import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import {
  useGetRequestDetailsQuery,
  useUpdateRequestMutation,
  useCreatePaidAmountMutation
} from '../../slices/requestsApiSlice'
import Notes from '../../components/Notes'
import Status from '../../components/Status'
import { toast } from 'react-toastify'
import { IoSend } from 'react-icons/io5'
import Icon from '../../components/Icon'
import moment from 'moment'
import Customer from '../../components/Customer'
import Documents from '../../components/Documents'

const PaymentScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()
  const navigate = useNavigate()

  const [paidAmount, setPaidAmount] = useState(0)

  const {
    data: request,
    isLoading,
    refetch,
    error
  } = useGetRequestDetailsQuery(requestId)

  const [updateRequest, { isLoading: loadingRequest }] =
    useUpdateRequestMutation()
  const [updatePaidAmount] = useCreatePaidAmountMutation()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateRequest({ requestId, isPaid: true }).unwrap()
      toast.success('Payment complete')
      navigate(`/admin/translate/${requestId}`)
      refetch()
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const submitPaidHandler = async (e) => {
    e.preventDefault()
    try {
      await updatePaidAmount({ requestId, paidAmount }).unwrap()
      toast.success('Amount updated')
      refetch()
      setPaidAmount(0)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='payment status'>
            <Link to='/admin/payment'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM, YYYY')} at{' '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-type'>
                    <Icon requestItem={request.requestItem} />
                    <div className='status-type-data'>
                      <h5>{request.requestItem}</h5>
                      {request.totalPrice ? (
                        <p>${request.totalPrice}</p>
                      ) : (
                        <p>Pending</p>
                      )}
                    </div>
                  </div>
                  <div className='status-payment'>
                    <div className='status-payment-total'>
                      <div>Total</div>
                      <div>${request.totalPrice}</div>
                    </div>
                    <div className='status-payment-paid'>
                      <div>Paid</div>
                      <div>${request.paidAmount}</div>
                    </div>
                    <hr />
                    <div className='status-payment-remaining'>
                      <h5>Remaining</h5>
                      <h5>${request.totalPrice - request.paidAmount}</h5>
                    </div>
                  </div>
                  <div className='status-paid'>
                    <Form
                      className='status-paid-form'
                      onSubmit={submitPaidHandler}
                    >
                      <Form.Group controlId='paidAmount'>
                        <Form.Control
                          type='number'
                          required
                          value={paidAmount}
                          onChange={(e) => setPaidAmount(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <button type='submit'>
                        <IoSend />
                      </button>
                    </Form>
                  </div>
                  <Link className='btn alt-btn' onClick={submitHandler}>
                    PAYMENT COMPLETE
                  </Link>
                  <Documents request={request} />
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='payment status'>
            {loadingRequest && <Loader />}
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM, YYYY')} at{' '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-data'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <Icon requestItem={request.requestItem} />
                </div>
                <div className='status-payment'>
                  <div className='status-payment-left'>
                    <div className='status-payment-left-total'>
                      <div>Total</div>
                      <div>${request.totalPrice}</div>
                    </div>
                    <div className='status-payment-left-paid'>
                      <div>Paid</div>
                      <div>${request.paidAmount}</div>
                    </div>
                    <hr />
                    <div className='status-payment-left-remaining'>
                      <div>Remaining</div>
                      <div>${request.totalPrice - request.paidAmount}</div>
                    </div>
                  </div>
                  <div className='status-payment-right'>
                    <form
                      className='status-form paymentForm mt-0'
                      onSubmit={submitPaidHandler}
                    >
                      <div className='label'>Update amount paid</div>
                      <div className='paymentForm-wrapper'>
                        <div className='paymentForm-input'>
                          <div className='input-container'>
                            <input
                              type='number'
                              name='paidAmount'
                              id='paidAmount'
                              value={paidAmount}
                              onChange={(e) => setPaidAmount(e.target.value)}
                            />
                          </div>
                        </div>
                        <button type='submit' className='send-btn'>
                          <IoSend />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='actions'>
                  <h5>Actions</h5>
                  <div className='btn-container'>
                    <button className='alt-btn' onClick={submitHandler}>
                      <span>PAYMENT COMPLETE</span>
                    </button>
                    <Documents request={request} />
                  </div>
                </div>
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default PaymentScreen
