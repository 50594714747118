import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import FormContainer from '../../components/FormContainer'
import {
  useGetRequestDetailsQuery,
  useUpdateRequestMutation
} from '../../slices/requestsApiSlice'
import Notes from '../../components/Notes'
import Status from '../../components/Status'
import Icon from '../../components/Icon'
import moment from 'moment'
import { toast } from 'react-toastify'
import Customer from '../../components/Customer'
import Documents from '../../components/Documents'

const QuoteScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()

  const navigate = useNavigate()

  const [deposit, setDeposit] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  const {
    data: request,
    isLoading,
    refetch,
    error
  } = useGetRequestDetailsQuery(requestId)

  const [updateRequest, { isLoading: loadingRequest }] =
    useUpdateRequestMutation()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateRequest({
        requestId,
        deposit,
        totalPrice,
        isQuoted: true,
        quotedAt: Date.now()
      }).unwrap()
      toast.success('Quote added')
      refetch()
      navigate(`/admin/payment/${requestId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  useEffect(() => {
    if (request) {
      setDeposit(request.deposit)
      setTotalPrice(request.totalPrice)
    }
  }, [request])

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='quote status'>
            <Link to='/admin/quote'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {loadingRequest && <Loader />}
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM, YYYY')} at{' '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <p className='status-date'></p>
                <Status request quote />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-type'>
                    <Icon requestItem={request.requestItem} />
                    <div className='status-type-data'>
                      <h5>{request.requestItem}</h5>
                      {request.totalPrice ? (
                        <p>${request.totalPrice}</p>
                      ) : (
                        <p>Pending</p>
                      )}
                    </div>
                  </div>
                  <div className='status-details'>
                    <p>Details</p>
                    <div>{request.details}</div>
                  </div>
                  <FormContainer>
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId='deposit' className='my-3'>
                        <Form.Label>Deposit</Form.Label>
                        <Form.Control
                          type='number'
                          value={deposit}
                          onChange={(e) => setDeposit(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId='totalPrice' className='my-3'>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type='number'
                          value={totalPrice}
                          onChange={(e) => setTotalPrice(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      {!request.isPaid && (
                        <button
                          type='submit'
                          className='btn alt-btn'
                          disabled={loadingRequest}
                        >
                          SUBMIT QUOTE
                        </button>
                      )}
                    </Form>
                  </FormContainer>
                  <Documents request={request} />
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='status quote'>
            {loadingRequest && <Loader />}
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM, YYYY')} at{' '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-data'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <Icon requestItem={request.requestItem} />
                </div>
                <div className='status-details'>
                  <p className='status-details-title'>Details</p>
                  <div>{request.details}</div>
                </div>
                <form
                  className='status-form quoteForm'
                  onSubmit={submitHandler}
                >
                  <div className='quoteForm-input'>
                    <div className='input-container'>
                      <label htmlFor='deposit'>Deposit</label>
                      <input
                        type='number'
                        name='deposit'
                        id='deposit'
                        value={deposit}
                        onChange={(e) => setDeposit(e.target.value)}
                      ></input>
                    </div>
                    <div className='input-container'>
                      <label htmlFor='totalPrice'>Price</label>
                      <input
                        type='number'
                        id='totalPrice'
                        name='totalPrice'
                        value={totalPrice}
                        onChange={(e) => setTotalPrice(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <button
                    type='submit'
                    id='submitQuote'
                    name='submitQuote'
                    className='alt-btn'
                    disabled={loadingRequest}
                  >
                    <span>SUBMIT QUOTE</span>
                  </button>
                </form>
                <div className='actions'>
                  <h5>Actions</h5>
                  <div className='btn-container'>
                    <label
                      htmlFor='submitQuote'
                      className='submitQuote alt-btn'
                      disabled={loadingRequest}
                    >
                      <span>SUBMIT QUOTE</span>
                    </label>
                    <Documents request={request} />
                  </div>
                </div>
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default QuoteScreen
