import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import FormContainer from '../../components/FormContainer'
import {
  useGetRequestDetailsQuery,
  useUpdateRequestMutation,
  useAddTranslatorMutation,
  useUploadTranslationMutation
} from '../../slices/requestsApiSlice'
import { useGetUsersQuery } from '../../slices/usersApiSlice'
import Notes from '../../components/Notes'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { toast } from 'react-toastify'
import Status from '../../components/Status'
import Icon from '../../components/Icon'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { IoSend } from 'react-icons/io5'
import { FaCloudUploadAlt } from 'react-icons/fa'
import Documents from '../../components/Documents'
import Customer from '../../components/Customer'

const TranslateScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()
  const { userInfo } = useSelector((state) => state.auth)
  const navigate = useNavigate()

  const [translator, setTranslator] = useState('')
  const [translation, setTranslation] = useState('')

  const {
    data: request,
    isLoading,
    error,
    refetch
  } = useGetRequestDetailsQuery(requestId)
  const [updateRequest, { isLoading: loadingRequest }] =
    useUpdateRequestMutation()
  const { data: users, isLoading: loadingUsers } = useGetUsersQuery()
  const [addTranslator, { isLoading: loadingTranslator }] =
    useAddTranslatorMutation()
  const [uploadTranslation] = useUploadTranslationMutation()

  const userArr = users || []

  const translators = userArr.filter((x) => x.isTranslator || x.isContractor)

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateRequest({
        requestId,
        translation,
        isTranslated: true,
        translatedAt: Date.now()
      }).unwrap()
      toast.success('Translation submitted')
      refetch()
      navigate(`/admin/review/${requestId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const submitTranslatorHandler = async (e) => {
    e.preventDefault()
    try {
      await addTranslator({
        requestId,
        translator
      }).unwrap()
      toast.success('Translator updated')
      refetch()
      setTranslator(translator)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('translation', e.target.files[0])
    try {
      const res = await uploadTranslation(formData).unwrap()
      toast.success(res.message)
      setTranslation(res.translation)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='translate status'>
            <Link to='/admin/translate'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-translator'>
                    <h5>Translator</h5>
                    <div>
                      {translators
                        .filter((users) => users._id === request.translator)
                        .map((x) => (
                          <div
                            key={x._id}
                          >{`${x.firstName} ${x.lastName}`}</div>
                        ))}
                    </div>
                    {userInfo.isOwner && (
                      <>
                        {loadingTranslator ? (
                          <Loader />
                        ) : (
                          <>
                            <small>Update Translator</small>
                            <Form
                              className='status-translator-form'
                              onSubmit={submitTranslatorHandler}
                            >
                              <Form.Group controlId='translator'>
                                {loadingUsers && <Loader />}
                                <Form.Select
                                  type='text'
                                  value={translator}
                                  onChange={(e) =>
                                    setTranslator(e.target.value)
                                  }
                                >
                                  {translators.map((translator) => (
                                    <option
                                      key={translator._id}
                                      value={translator._id}
                                    >
                                      {translator.firstName +
                                        ' ' +
                                        translator.lastName}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                              <button type='submit'>
                                <IoSend />
                              </button>
                            </Form>
                          </>
                        )}
                      </>
                    )}
                    <div className='status-details'>
                      <small>Details</small>
                      <div>{request.details}</div>
                    </div>
                    <FormContainer className='status-translation'>
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='translation' className='my-2'>
                          <small>Upload Translated Document</small>
                          <Form.Control
                            type='file'
                            label='Choose Document'
                            onChange={uploadFileHandler}
                            className='custom-field'
                          ></Form.Control>
                        </Form.Group>
                        <button
                          type='submit'
                          className='btn alt-btn'
                          disabled={isLoading}
                        >
                          SUBMIT TRANSLATION
                        </button>
                      </Form>
                    </FormContainer>
                  </div>
                  <Documents request={request} />
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='translate status'>
            {loadingRequest && <Loader />}
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-data'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <Icon requestItem={request.requestItem} />
                </div>
                <div className='status-translator mt-5'>
                  <h5>Translator</h5>
                  <div>
                    {translators
                      .filter((users) => users._id === request.translator)
                      .map((x) => (
                        <p key={x._id}>{`${x.firstName} ${x.lastName}`}</p>
                      ))}
                  </div>
                  <div className='status-translator-info'>
                    <div className='status-translator-info-details'>
                      <div className='status-translator-info-details-title'>
                        Details
                      </div>
                      <p>{request.details}</p>
                    </div>
                    <div className='status-translator-info-right'>
                      {userInfo.isOwner && (
                        <>
                          {loadingTranslator ? (
                            <Loader />
                          ) : (
                            <>
                              <form
                                className='status-form translatorForm mt-0'
                                onSubmit={submitTranslatorHandler}
                              >
                                <div className='label'>Update Translator</div>
                                {loadingUsers && <Loader />}
                                <div className='translatorForm-wrapper'>
                                  <select
                                    type='text'
                                    name='translator'
                                    id='translator'
                                    value={translator}
                                    onChange={(e) =>
                                      setTranslator(e.target.value)
                                    }
                                  >
                                    {translators.map((translator) => (
                                      <option
                                        key={translator._id}
                                        value={translator._id}
                                      >
                                        {translator.firstName +
                                          ' ' +
                                          translator.lastName}
                                      </option>
                                    ))}
                                  </select>
                                  <button type='submit' className='send-btn'>
                                    <IoSend />
                                  </button>
                                </div>
                              </form>
                            </>
                          )}
                        </>
                      )}
                      <form
                        className='status-form translationForm mt-0'
                        onSubmit={submitHandler}
                      >
                        <div className='label mt-4'>
                          Upload Translated Document
                        </div>
                        <label htmlFor='translation' className='uploadFile'>
                          <FaCloudUploadAlt className='cloud' />
                        </label>
                        <input
                          type='file'
                          id='translation'
                          name='translation'
                          accept='.pdf'
                          onChange={uploadFileHandler}
                        />
                        <button
                          type='submit'
                          id='submitTranslation'
                          className='btn alt-btn'
                          disabled={isLoading}
                        >
                          SUBMIT TRANSLATION
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className='actions'>
                    <h5>Actions</h5>
                    <div className='btn-container'>
                      <label
                        htmlFor='submitTranslation'
                        className='submitTranslation alt-btn'
                      >
                        <span>SUBMIT TRANSLATION</span>
                      </label>
                      <Documents request={request} />
                    </div>
                  </div>
                </div>
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}
export default TranslateScreen
