import { useState } from 'react'
import Loader from './Loader'
import { Form } from 'react-bootstrap'
import {
  useGetRequestDetailsQuery,
  useCreateNoteMutation
} from '../slices/requestsApiSlice'
import { toast } from 'react-toastify'
import moment from 'moment'
import { IoSend } from 'react-icons/io5'

const Notes = ({ requestId }) => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data: request, refetch } = useGetRequestDetailsQuery(requestId)
  const [createNote, { isLoading: loadingRequestNote }] =
    useCreateNoteMutation()

  const [comment, setComment] = useState('')

  const submitNoteHandler = async (e) => {
    e.preventDefault()
    try {
      await createNote({
        requestId,
        comment
      }).unwrap()
      toast.success('Note submitted')
      refetch()
      setComment('')
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      {w < 960 ? (
        <div className='status-notes'>
          <h5>Notes</h5>
          {loadingRequestNote ? (
            <Loader />
          ) : (
            <>
              {request.notes.length === 0 && (
                <p className='status-notes-comment'>No Notes...</p>
              )}
              {[...request.notes].reverse().map((note) => (
                <div key={note._id}>
                  {note.name === 'CTS' ? (
                    <div className='status-notes-comment system-dark'>
                      <h5>{note.name}</h5>
                      <p>{note.comment}</p>
                      <small>
                        {moment(note.createdAt).format('DD MMM YYYY')}
                        {' at '}
                        {moment(note.createdAt).format('H:mm')}
                      </small>
                    </div>
                  ) : (
                    <div className='status-notes-comment'>
                      <h5>{note.name}</h5>
                      <p>{note.comment}</p>
                      <small>
                        {moment(note.createdAt).format('DD MMM YYYY')}
                        {' at '}
                        {moment(note.createdAt).format('H:mm')}
                      </small>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {!request.isDelivered && (
            <div>
              <Form className='status-notes-form' onSubmit={submitNoteHandler}>
                <Form.Group controlId='comment'>
                  <Form.Control
                    as='textarea'
                    required
                    value={comment}
                    placeholder='Leave a comment...'
                    onChange={(e) => setComment(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <button disabled={loadingRequestNote} type='submit'>
                  <IoSend />
                </button>
              </Form>
            </div>
          )}
        </div>
      ) : (
        <div className='status-notes'>
          <h5 className='status-notes-title'>Notes</h5>
          {loadingRequestNote ? (
            <Loader />
          ) : (
            <>
              {request.notes.length === 0 && (
                <div className='status-notes-comment system-dark'>
                  <div className='system-dark-wrapper'>
                    <h5>CTS</h5>
                    <p>No notes available at this time.</p>
                  </div>
                </div>
              )}
              {[...request.notes].reverse().map((note) => (
                <div key={note._id}>
                  {note.name === 'CTS' ? (
                    <div className='status-notes-comment system-dark'>
                      <div className='system-dark-wrapper'>
                        <h5>{note.name}</h5>
                        <p>{note.comment}</p>
                        <small>
                          {moment(note.createdAt).format('DD MMM YYYY')}
                          {' at '}
                          {moment(note.createdAt).format('H:mm')}
                        </small>
                      </div>
                    </div>
                  ) : (
                    <div className='status-notes-comment'>
                      <h5>{note.name}</h5>
                      <p>{note.comment}</p>
                      <small>
                        {moment(note.createdAt).format('DD MMM YYYY')}
                        {' at '}
                        {moment(note.createdAt).format('H:mm')}
                      </small>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {!request.isDelivered && (
            <div className='form-wrapper'>
              <form
                className='status-form notesForm mt-3'
                onSubmit={submitNoteHandler}
              >
                <input
                  as='textarea'
                  name='comment'
                  id='comment'
                  value={comment}
                  placeholder='Leave a comment...'
                  onChange={(e) => setComment(e.target.value)}
                  required
                ></input>
                <button
                  disabled={loadingRequestNote}
                  type='submit'
                  className='send-btn'
                >
                  <IoSend />
                </button>
              </form>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Notes
