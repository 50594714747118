import { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import HeaderDark from '../components/HeaderDark'
import { Form, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import { useRegisterMutation } from '../slices/usersApiSlice'
import { setCredentials } from '../slices/authSlice'
import { toast } from 'react-toastify'
import logo_dark from '../assets/logo_dark.png'

const RegisterScreen = () => {
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [branch, setBranch] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [find, setFind] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [register, { isLoading }] = useRegisterMutation()

  const { userInfo } = useSelector((state) => state.auth)

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const redirect = sp.get('redirect') || '/'

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, redirect, navigate])

  const submitHandler = async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('Passwords do not match')
    } else {
      try {
        const res = await register({
          firstName,
          middleName,
          lastName,
          email,
          branch,
          phone,
          password,
          confirmPassword,
          find
        }).unwrap()
        dispatch(setCredentials({ ...res }))
        toast.success('Account created')
        navigate(redirect)
      } catch (err) {
        toast.error(err?.data?.message || err.error)
      }
    }
  }
  return (
    <>
      <div className='mobile'>
        <HeaderDark />
        <FormContainer>
          <h1 className='text-center mt-5'>REGISTER</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='firstName' className='my-3'>
              <Form.Control
                type='text'
                placeholder='FIRST NAME'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='middleName' className='my-3'>
              <Form.Control
                type='text'
                placeholder='MIDDLE NAME'
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='lastName' className='my-3'>
              <Form.Control
                type='text'
                placeholder='LAST NAME'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='email' className='my-3'>
              <Form.Control
                type='email'
                placeholder='EMAIL'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId='branch' className='my-3'>
              <Form.Select
                type='text'
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                required
              >
                <option>BRANCH</option>
                <option>ARMY</option>
                <option>NAVY</option>
                <option>MARINES</option>
                <option>COAST GUARD</option>
                <option>AIR FORCE</option>
                <option>SPACE FORCE</option>
                <option>CIVILIAN</option>
                <option>OTHER</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId='phone' className='my-3'>
              <Form.Control
                type='tel'
                placeholder='PHONE'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password' className='my-3'>
              <Form.Control
                type='password'
                placeholder='PASSWORD'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='confirmPassword' className='my-3'>
              <Form.Control
                type='password'
                placeholder='CONFIRM PASSWORD'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId='find' className='my-3'>
              <Form.Select
                type='text'
                value={find}
                onChange={(e) => setFind(e.target.value)}
              >
                <option>HOW DID YOU FIND US</option>
                <option>USO</option>
                <option>LEGAL OFFICE</option>
                <option>FRIENDS</option>
                <option>COLLEAGUES</option>
                <option>FACEBOOK</option>
                <option>FLYER</option>
                <option>OTHER</option>
              </Form.Select>
            </Form.Group>
            <button type='submit' className='button' disabled={isLoading}>
              SUBMIT
            </button>
          </Form>
          {isLoading && <Loader />}
        </FormContainer>
      </div>

      {/* Desktop */}
      <div className='desktop'>
        <div className='register-container'>
          <div className='overlay-container'>
            <div className='overlay title'>
              <div id='stars'></div>
              <div id='stars2'></div>
              <div id='stars3'></div>
              <div className='overlay-panel overlay-left'>
                <h1>Welcome Back!</h1>
                <Link
                  to={redirect ? `/login?redirect=${redirect}` : '/login'}
                  className='sign-in-btn'
                >
                  SIGN IN
                </Link>
              </div>
            </div>
          </div>
          <FormContainer className='sign-up-container'>
            <Link to='/'>
              <img src={logo_dark} alt='CTS' />
            </Link>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='firstName' className='my-3'>
                <Form.Control
                  type='text'
                  placeholder='FIRST NAME'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='middleName' className='my-3'>
                <Form.Control
                  type='text'
                  placeholder='MIDDLE NAME'
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='lastName' className='my-3'>
                <Form.Control
                  type='text'
                  placeholder='LAST NAME'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email' className='my-3'>
                <Form.Control
                  type='email'
                  placeholder='EMAIL'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId='branch' className='my-3'>
                <Form.Select
                  type='text'
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                >
                  <option>BRANCH</option>
                  <option>ARMY</option>
                  <option>NAVY</option>
                  <option>MARINES</option>
                  <option>COAST GUARD</option>
                  <option>AIR FORCE</option>
                  <option>SPACE FORCE</option>
                  <option>CIVILIAN</option>
                  <option>OTHER</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId='phone' className='my-3'>
                <Form.Control
                  type='tel'
                  placeholder='PHONE'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='password' className='my-3'>
                <Form.Control
                  type='password'
                  placeholder='PASSWORD'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='confirmPassword' className='my-3'>
                <Form.Control
                  type='password'
                  placeholder='CONFIRM PASSWORD'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId='find' className='my-3'>
                <Form.Select
                  type='text'
                  value={find}
                  onChange={(e) => setFind(e.target.value)}
                >
                  <option>HOW DID YOU FIND US</option>
                  <option>USO</option>
                  <option>LEGAL OFFICE</option>
                  <option>FRIENDS</option>
                  <option>COLLEAGUES</option>
                  <option>FACEBOOK</option>
                  <option>FLYER</option>
                  <option>OTHER</option>
                </Form.Select>
              </Form.Group>
              <button type='submit' className='button' disabled={isLoading}>
                SUBMIT
              </button>
            </Form>
          </FormContainer>
        </div>
      </div>
    </>
  )
}

export default RegisterScreen
