import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDark from '../components/HeaderDark'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { useGetMyRequestsQuery } from '../slices/requestsApiSlice'
import { useGetServicesQuery } from '../slices/servicesApiSlice'
import moment from 'moment'
import Footer from '../components/Footer'

const ProfileRequestsScreen = () => {
  const { data: requests, isLoading, error } = useGetMyRequestsQuery()
  const { data: services, isLoading: loadingService } = useGetServicesQuery()

  const requestArr = requests || []

  const current = requestArr.filter((reqs) => !reqs.isCompleted).length
  const total = requestArr.length

  return (
    <>
      <HeaderDark />
      <section className='profile'>
        <Link to='/profile'>
          <h3>
            <MdOutlineArrowBackIosNew className='back-btn' />
          </h3>
        </Link>
        <h1 className='text-center profile-title'>MY REQUESTS</h1>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <div className='p-4'>
            <div className='requests'>
              <div className='requests-count'>
                <div className='requests-count-current'>
                  <h4>Current</h4>
                  <div className='count'>{current} Requests</div>
                </div>
                <div className='requests-count-history'>
                  <h4>Total</h4>
                  <div className='count'>{total} Requests</div>
                </div>
              </div>
              <div className='requests-info mt-4'>
                {[...requests].reverse().map((request) => (
                  <div key={request._id} className='requests-info-data mb-4'>
                    <Link to={`/request/${request._id}`}>
                      {loadingService ? (
                        <Loader />
                      ) : (
                        services
                          .filter(
                            (service) => service.name === request.requestItem
                          )
                          .map((filteredService) => (
                            <img
                              key={filteredService._id}
                              src={filteredService.icon}
                              alt='icon'
                            />
                          ))
                      )}
                      <h5 className='requests-info-data-title text-center mb-5'>
                        {request.requestItem}
                      </h5>
                      <div className='requests-info-data-date'>
                        <p>Date</p>
                        <p>
                          {moment(request.createdAt).format('MMM DD, YYYY')}
                        </p>
                      </div>
                      <div className='requests-info-data-number'>
                        <p>Request #</p>
                        <p>{request._id}</p>
                      </div>
                      <div className='requests-info-data-total'>
                        <p>Total</p>
                        {request.totalPrice === 0 ? (
                          <p>Pending</p>
                        ) : (
                          <p>${request.totalPrice}</p>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  )
}

export default ProfileRequestsScreen
