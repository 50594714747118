import { useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import {
  useGetRequestDetailsQuery,
  useUpdateRequestMutation,
  useCreatePaidAmountMutation
} from '../../slices/requestsApiSlice'
import Notes from '../../components/Notes'
import { toast } from 'react-toastify'
import Status from '../../components/Status'
import Icon from '../../components/Icon'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { IoSend } from 'react-icons/io5'
import Customer from '../../components/Customer'
import Documents from '../../components/Documents'

const DeliveryScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()
  const navigate = useNavigate()

  const [paidAmount, setPaidAmount] = useState(0)

  const {
    data: request,
    isLoading,
    error,
    refetch
  } = useGetRequestDetailsQuery(requestId)
  const [updateRequest] = useUpdateRequestMutation()
  const [updatePaidAmount] = useCreatePaidAmountMutation()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateRequest({ requestId, isDelivered: true }).unwrap()
      toast.success('Document delivered')
      refetch()
      navigate(`/admin/complete/${requestId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const submitPaidHandler = async (e) => {
    e.preventDefault()
    try {
      await updatePaidAmount({ requestId, paidAmount }).unwrap()
      toast.success('Amount updated')
      refetch()
      setPaidAmount(0)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='review status'>
            <Link to='/admin/delivery'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate review delivery />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-type'>
                    <Icon requestItem={request.requestItem} />
                    <div className='status-type-data'>
                      <h5>{request.requestItem}</h5>
                      {request.totalPrice ? (
                        <p>${request.totalPrice}</p>
                      ) : (
                        <p>Pending</p>
                      )}
                    </div>
                  </div>
                  {request.paidAmount < request.totalPrice && (
                    <>
                      <div className='status-payment'>
                        <div className='status-payment-total'>
                          <div>Total</div>
                          <div>${request.totalPrice}</div>
                        </div>
                        <div className='status-payment-paid'>
                          <div>Paid</div>
                          <div>${request.paidAmount}</div>
                        </div>
                        <hr />
                        <div className='status-payment-remaining'>
                          <h5>Remaining</h5>
                          <h5>${request.totalPrice - request.paidAmount}</h5>
                        </div>
                      </div>
                      <div className='status-paid'>
                        <Form
                          className='status-paid-form'
                          onSubmit={submitPaidHandler}
                        >
                          <Form.Group controlId='paidAmount'>
                            <Form.Control
                              type='number'
                              required
                              value={paidAmount}
                              onChange={(e) => setPaidAmount(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <button type='submit'>
                            <IoSend />
                          </button>
                        </Form>
                      </div>
                    </>
                  )}
                  <div className='status-translator'>
                    <div className='status-details'>
                      <small>Details</small>
                      <div>{request.details}</div>
                    </div>
                    <Documents request={request} />
                    <div className='status-documents'>
                      <Link className='btn alt-btn' onClick={submitHandler}>
                        DELIVERED
                      </Link>
                    </div>
                  </div>
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='delivery status'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate review delivery />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-data'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <Icon requestItem={request.requestItem} />
                </div>
                <div className='status-payment'>
                  <div className='status-payment-left'>
                    <div className='status-payment-left-total'>
                      <div>Total</div>
                      <div>${request.totalPrice}</div>
                    </div>
                    <div className='status-payment-left-paid'>
                      <div>Paid</div>
                      <div>${request.paidAmount}</div>
                    </div>
                    <hr />
                    <div className='status-payment-left-remaining'>
                      <div>Remaining</div>
                      <div>${request.totalPrice - request.paidAmount}</div>
                    </div>
                  </div>
                  <div className='status-payment-right'>
                    <div className='status-details mt-0'>
                      <p className='status-details-title'>Details</p>
                      <div>{request.details}</div>
                    </div>
                  </div>
                </div>
                {request.paidAmount < request.totalPrice && (
                  <div className='flex-45'>
                    <form
                      className='status-form paymentForm'
                      onSubmit={submitPaidHandler}
                    >
                      <div className='label'>Update amount paid</div>
                      <div className='paymentForm-wrapper'>
                        <div className='paymentForm-input'>
                          <div className='input-container'>
                            <input
                              type='number'
                              name='paidAmount'
                              id='paidAmount'
                              value={paidAmount}
                              onChange={(e) => setPaidAmount(e.target.value)}
                            />
                          </div>
                        </div>
                        <button type='submit' className='send-btn'>
                          <IoSend />
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                <div className='status-delivery'>
                  <Documents request={request} />
                  <div className='actions'>
                    <h5 className='actions-title'>Actions</h5>
                    <button className='pri-btn' onClick={submitHandler}>
                      <h5>DELIVERED</h5>
                    </button>
                  </div>
                </div>
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default DeliveryScreen
