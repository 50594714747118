import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetUsersQuery } from '../../slices/usersApiSlice'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { MdArrowForwardIos } from 'react-icons/md'

const TranslatorListScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data: users, isLoading, error } = useGetUsersQuery()
  const { data: requests, isLoading: loadingRequests } = useGetRequestsQuery()

  const userArr = users || []

  const translators = userArr.filter(
    (user) => user.isTranslator || user.isContractor
  )

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='translators list'>
            <Link to='/admin/dashboard'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='text-center deliveries-title'>TRANSLATORS</h1>
            <div className='p-4'>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                <>
                  {translators.map((translator) => (
                    <div key={translator._id} className='list-data mb-0'>
                      <Link
                        to={`/admin/translatorlist/${translator._id}`}
                        className='list-data-split align-center mb-0 py-2'
                      >
                        <div className='list-data-split-translator'>
                          <h3 className='name'>
                            {translator.lastName}, {translator.firstName}
                          </h3>
                          {loadingRequests ? (
                            <Loader />
                          ) : (
                            <div className='row-2 small-text'>
                              {
                                requests.filter(
                                  (request) =>
                                    request.translator === translator._id &&
                                    !request.isCompleted
                                ).length
                              }{' '}
                              Documents
                            </div>
                          )}
                        </div>
                        <MdArrowForwardIos />
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='translators list'>
            <h3 className='list-title'>TRANSLATORS</h3>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                {translators.map((translator) => (
                  <div key={translator._id} className='list-data'>
                    <Link
                      to={`/admin/translatorlist/${translator._id}`}
                      className='list-data-wrapper'
                    >
                      <h3 className='list-data-left'>
                        {translator.lastName}, {translator.firstName}
                      </h3>
                      {loadingRequests ? (
                        <Loader />
                      ) : (
                        <div className='list-data-right'>
                          {
                            requests.filter(
                              (request) =>
                                request.translator === translator._id &&
                                !request.isCompleted
                            ).length
                          }{' '}
                          Documents
                        </div>
                      )}
                    </Link>
                  </div>
                ))}
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default TranslatorListScreen
