import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderDark from '../components/HeaderDark'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { FiEdit3 } from 'react-icons/fi'
import Footer from '../components/Footer'

const ProfileInfoScreen = () => {
  const { userInfo } = useSelector((state) => state.auth)
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <HeaderDark />
      <section className='profile'>
        <Link to='/profile'>
          <h3>
            <MdOutlineArrowBackIosNew className='back-btn' />
          </h3>
        </Link>
        <h1 className='text-center profile-title'>MY INFO</h1>
        <div className='p-4'>
          {w < 960 ? (
            <div className='mobile'>
              <div className='profile-name'>
                <h4>General Information</h4>
                <Link to='/profile/edit'>
                  <h4>
                    <FiEdit3 />
                  </h4>
                </Link>
              </div>
              <div className='profile-info'>
                <ul className='left'>
                  <li>First Name</li>
                  {userInfo.middleName && <li>Middle Name</li>}
                  <li>Last Name</li>
                  <li>Phone</li>
                </ul>
                <ul className='right'>
                  <li>{userInfo.firstName}</li>
                  <li>{userInfo.middleName}</li>
                  <li>{userInfo.lastName}</li>
                  <li>{userInfo.phone}</li>
                </ul>
              </div>
              <h4 className='mt-5'>Login Information</h4>
              <div className='profile-login'>
                <ul className='left'>
                  <li>Email</li>
                  <li>Password</li>
                </ul>
                <ul className='right'>
                  <li>{userInfo.email}</li>
                  <li>..............</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className='desktop'>
              <div className='profile-flex'>
                <div className='profile-flex-left'>
                  <h4>General Information</h4>
                  <div className='profile-info'>
                    <ul className='left'>
                      <li>First Name</li>
                      {userInfo.middleName && <li>Middle Name</li>}
                      <li>Last Name</li>
                      <li>Phone</li>
                    </ul>
                    <ul className='right'>
                      <li>{userInfo.firstName}</li>
                      <li>{userInfo.middleName}</li>
                      <li>{userInfo.lastName}</li>
                      <li>{userInfo.phone}</li>
                    </ul>
                  </div>
                </div>
                <div className='profile-flex-right'>
                  <div className='right-text'>
                    <h4>Login Information</h4>
                    <Link to='/profile/edit'>
                      <h4>
                        <FiEdit3 />
                      </h4>
                    </Link>
                  </div>
                  <div className='profile-login'>
                    <ul className='left'>
                      <li>Email</li>
                      <li>Password</li>
                    </ul>
                    <ul className='right'>
                      <li>{userInfo.email}</li>
                      <li>..............</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ProfileInfoScreen
