import React from 'react'

const Customer = ({ customer }) => {
  return (
    <div className='status-info'>
      <h5>Customer</h5>
      <div className='status-info-name'>
        {customer.firstName} {customer.middleName} {customer.lastName}
      </div>
      <div className='status-info-email'>{customer.email}</div>
      <div className='status-info-phone'>{customer.phone}</div>
    </div>
  )
}

export default Customer
