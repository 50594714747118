import { Link } from 'react-router-dom'
import HeaderDarkAdmin from './HeaderDarkAdmin'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Message from './Message'
import Loader from './Loader'
import { useGetRequestsQuery } from '../slices/requestsApiSlice'
import Icon from '../components/Icon'
import moment from 'moment'

const StatusList = ({ status }) => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data, isLoading, error } = useGetRequestsQuery()
  const requestList = data || []
  let filteredList

  switch (status) {
    case 'quote':
      filteredList = requestList.filter((request) => !request.isQuoted)
      break
    case 'payment':
      filteredList = requestList.filter(
        (request) => request.isQuoted && !request.isPaid
      )
      break
    case 'translate':
      filteredList = requestList.filter(
        (request) => request.isQuoted && request.isPaid && !request.isTranslated
      )
      break
    case 'review':
      filteredList = requestList.filter(
        (request) =>
          request.isQuoted &&
          request.isPaid &&
          request.isTranslated &&
          !request.isReviewed
      )
      break
    case 'delivery':
      filteredList = requestList.filter(
        (request) =>
          request.isQuoted &&
          request.isPaid &&
          request.isTranslated &&
          request.isReviewed &&
          !request.isDelivered
      )
      break
    case 'complete':
      filteredList = requestList.filter(
        (request) =>
          request.isQuoted &&
          request.isPaid &&
          request.isTranslated &&
          request.isReviewed &&
          request.isDelivered
      )
      break
    default:
      filteredList = 'Error: unknown status'
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='status-list list'>
            <Link to='/admin/dashboard'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='text-center deliveries-title'>
              {status.toUpperCase()}
            </h1>
            <div className='p-4'>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                <>
                  <div className='header'>
                    <div>Name</div>
                    <div>Recieved</div>
                  </div>
                  {[...filteredList].reverse().map((data) => (
                    <div key={data._id} className='list-data no-border'>
                      <Link
                        className='list-data-split'
                        to={`/admin/${status}/${data._id}`}
                      >
                        <div className='list-data-split-left'>
                          <h3 className='row-1'>
                            {data.lastName}, {data.firstName}
                          </h3>
                          <div className='row-2 small-text'>
                            {data.requestItem}
                          </div>
                        </div>
                        <div className='list-data-split-right'>
                          <h3 className='row-1'>
                            {moment(data.createdAt).format('DD MMM, YYYY')}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='list status'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title mb-4'>{status.toUpperCase()}</h3>
                {[...filteredList].reverse().map((data) => (
                  <div key={data._id} className='list-complete'>
                    <Link
                      to={`/admin/${status}/${data._id}`}
                      className='list-complete-wrapper'
                    >
                      <div className='list-complete-left'>
                        <Icon requestItem={data.requestItem} />
                        <h3 className='list-complete-left-name'>
                          {data.lastName}, {data.firstName}
                        </h3>
                      </div>
                      <div className='list-complete-right'>
                        <h3 className='list-complete-right-date'>
                          {moment(data.createdAt).format('DD MMM, YYYY')}
                        </h3>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default StatusList
