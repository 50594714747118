import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useGetRequestDetailsQuery } from '../../slices/requestsApiSlice'
import { useGetUsersQuery } from '../../slices/usersApiSlice'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import Status from '../../components/Status'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Notes from '../../components/Notes'
import Documents from '../../components/Documents'
import Customer from '../../components/Customer'

const CompleteScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()

  const {
    data: request,
    isLoading,
    error
  } = useGetRequestDetailsQuery(requestId)
  const { data: users } = useGetUsersQuery()

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='complete status'>
            <Link to='/admin/complete'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status
                  request
                  quote
                  payment
                  translate
                  review
                  delivery
                  complete
                />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-translator'>
                    <div className='duo'>
                      <div className='status-type'>
                        <div className='status-type-data'>
                          <h5>{request.requestItem}</h5>
                          {request.totalPrice ? (
                            <p>${request.totalPrice}</p>
                          ) : (
                            <p>Pending</p>
                          )}
                        </div>
                      </div>
                      <div className='duo-translator'>
                        <h5>Translator</h5>
                        <div className=''>
                          {users.filter(
                            (user) => user._id === request.translator
                          )[0].firstName +
                            ' ' +
                            users.filter(
                              (user) => user._id === request.translator
                            )[0].lastName}
                        </div>
                      </div>
                    </div>
                    <div className='status-details'>
                      <small>Details</small>
                      <div>{request.details}</div>
                    </div>
                    <Documents request={request} />
                  </div>
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='complete status'>
            <Link to='/admin/complete'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status
                  request
                  quote
                  payment
                  translate
                  review
                  delivery
                  complete
                />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-service'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <div className='status-type-translator'>
                    <h5>Translator</h5>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div>
                        {users.filter(
                          (user) => user._id === request.translator
                        )[0].firstName +
                          ' ' +
                          users.filter(
                            (user) => user._id === request.translator
                          )[0].lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className='status-details'>
                  <small>Details</small>
                  <div>{request.details}</div>
                </div>
                <Documents request={request} />
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default CompleteScreen
