import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HeaderDark from '../components/HeaderDark'
import { toast } from 'react-toastify'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useProfileMutation } from '../slices/usersApiSlice'
import { setCredentials } from '../slices/authSlice'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import FormContainer from '../components/FormContainer'
import Footer from '../components/Footer'

const ProfileEditScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo } = useSelector((state) => state.auth)

  const [updateProfile, { isLoading: loadingUpdateProfile }, error] =
    useProfileMutation()

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName)
      setMiddleName(userInfo.middleName)
      setLastName(userInfo.lastName)
      setEmail(userInfo.email)
      setPhone(userInfo.phone)
    }
  }, [
    userInfo,
    userInfo.firstName,
    userInfo.middleName,
    userInfo.lastName,
    userInfo.email,
    userInfo.phone
  ])

  const submitHandler = async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('Passwords do not match')
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          firstName,
          middleName,
          lastName,
          email,
          phone,
          password
        }).unwrap()
        dispatch(setCredentials(res))
        toast.success('Profile updated successfully')
        navigate('/profile/info')
      } catch (err) {
        toast.error(err?.data?.message || err.error)
      }
    }
  }
  return (
    <>
      <HeaderDark />
      <section className='profile edit'>
        <Link to='/profile/info'>
          <h3>
            <MdOutlineArrowBackIosNew className='back-btn' />
          </h3>
        </Link>
        <h1 className='profile-title'>UPDATE INFO</h1>
        <FormContainer>
          <div className='p-4'>
            {loadingUpdateProfile ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form onSubmit={submitHandler} className='profile-form'>
                {w >= 960 && <h4>Name</h4>}
                <div className='profile-form-flex'>
                  <Form.Group controlId='firstName' className='my-3'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='First Name'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='middleName' className='my-3'>
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Middle Name'
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='lastName' className='my-3'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Last Name'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </div>
                {w >= 960 && <h4 className='profile-label'>Contact</h4>}
                <div className='profile-form-flex'>
                  <Form.Group controlId='email' className='my-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='phone' className='my-3'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type='tel'
                      placeholder='Phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </div>
                {w >= 960 && <h4 className='profile-label'>Security</h4>}
                <div className='profile-form-flex'>
                  <Form.Group controlId='password' className='my-3'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId='confirmPassword' className='my-3'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Confirm Password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </div>
                <button type='submit' className='button'>
                  UPDATE
                </button>
              </Form>
            )}
          </div>
        </FormContainer>
      </section>
      <Footer />
    </>
  )
}

export default ProfileEditScreen
