import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLogoutMutation } from '../slices/usersApiSlice'
import { logout } from '../slices/authSlice'
import logo from '../assets/logo.png'

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [navColor, setNavColor] = useState('transparent')
  const { userInfo } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [logoutApiCall] = useLogoutMutation()

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap()
      dispatch(logout())
      navigate('/login')
    } catch (err) {
      console.log(err)
    }
  }

  const menuHandler = (e) => {
    setIsMenuActive((current) => !current)
  }

  const navScroll = () => {
    window.scrollY > 10 ? setNavColor('#00001de0') : setNavColor('transparent')
  }

  useEffect(() => {
    window.addEventListener('scroll', navScroll)
    return () => {
      window.removeEventListener('scroll', navScroll)
    }
  }, [])

  return (
    <>
      <div id='overlay' className={isMenuActive ? 'overlay-show' : ''}></div>
      <div
        id='mobile-menu'
        className={
          isMenuActive ? 'mobile-main-menu show-menu' : 'mobile-main-menu'
        }
      >
        <ul>
          {userInfo ? (
            <>
              {userInfo && userInfo.isAdmin && (
                <li className='mobile-only'>
                  <Link to='/admin/dashboard'>Admin</Link>
                </li>
              )}
              <li className='mobile-only'>
                <Link to='/profile'>Profile</Link>
              </li>
              <li className='mobile-only'>
                <Link onClick={logoutHandler}>Logout</Link>
              </li>
            </>
          ) : (
            <li className='mobile-only'>
              <Link to='/login'>Login</Link>
            </li>
          )}
          <li>
            <Link to='/military'>Military Customers</Link>
          </li>
          <li>
            <Link to='/services/business'>Business Customers</Link>
          </li>
          <li>
            <Link to='/services'>Services</Link>
          </li>
          <li>
            <Link to='/submitrequest'>Request Form</Link>
          </li>
          <li>
            <Link to='/about'>About Us</Link>
          </li>
          <li>
            <Link to='/contact'>Contact Us</Link>
          </li>
        </ul>
      </div>
      <header
        className='main-header'
        style={{
          backgroundColor: navColor,
          transition: 'all 1s'
        }}
      >
        <div className='logo'>
          <Link to='/'>
            <img src={logo} alt='CTS' />
            <span className='desktop'>Central Translation Services</span>
          </Link>
        </div>
        <nav className='desktop-main-menu'>
          <ul>
            {userInfo ? (
              <>
                {userInfo.isAdmin && (
                  <li className='desktop'>
                    <Link to='/admin/dashboard'>Admin</Link>
                  </li>
                )}
                <li className='desktop'>
                  <Link to='/profile'>Profile</Link>
                </li>
                <li className='desktop'>
                  <Link onClick={logoutHandler}>Logout</Link>
                </li>
              </>
            ) : (
              <li className='desktop'>
                <Link to='/login'>Login</Link>
              </li>
            )}
          </ul>
        </nav>
      </header>

      <button
        id='menu-btn'
        className={isMenuActive ? 'hamburger open' : 'hamburger'}
        onClick={menuHandler}
        type='button'
      >
        <span className='hamburger-top'></span>
        <span className='hamburger-middle'></span>
        <span className='hamburger-bottom'></span>
      </button>
    </>
  )
}

export default Header
