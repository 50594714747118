import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Title from '../../components/Title'

const InterpretationScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title />

      <section className='service-items'>
        {w < 960 ? (
          <div id='title' className='mt-3'>
            <span className='top-line screens '>Interpretation</span>
          </div>
        ) : (
          <div className='service-dekstop'>
            <div className='service-desktop-title'>
              <div className='service-desktop-title-left'>
                <span className='upper-text'>Interpretation</span>
              </div>
              <div className='service-desktop-title-right'>
                <Link className='btn' to='/submitrequest'>
                  REQUEST FORM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className='container px-4'>
          <h1 className='pb-4'>
            On-Site Interpretation & Bilingual Admin Services
          </h1>
          <h2>On-Site Medical Interpretations</h2>
          <p>
            We will arrange you a translator for your appointments anywhere in
            Okinawa.
          </p>
          <h2 className='pt-5'>Bilingual Admin Services</h2>
          <p>
            Calling local businesses for inquiries, booking, sending emails on
            your behalf, filling out applications, issues large and small, we
            will gladly assist you!
          </p>
          <h2 className='pt-5'>Bilingual Emcee & Event Announcement</h2>
          <p>
            We can arrange for a professional bilingual master of ceremonies for
            any occasion in Okinawa!
          </p>
        </div>
        <div className='btn-container'>
          <Link className='btn' to='/submitrequest'>
            REQUEST FORM
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default InterpretationScreen
