import { Link } from 'react-router-dom'

const News = ({ news }) => {
  return (
    <div className='news'>
      <div className='container px-4'>
        <div className='news my-3'>
          <Link to={news.url} target='blank'>
            <div className='overlay'>
              <h1>{news.name}</h1>
            </div>
            <img src={news.image} alt='services' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default News
