import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLogoutMutation } from '../slices/usersApiSlice'
import { logout } from '../slices/authSlice'
import logo_dark from '../assets/logo_dark.png'

const HeaderDarkAdmin = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [navColor, setNavColor] = useState('transparent')
  const [navDesktopColor, setNavDesktopColor] = useState('#e0e5ec')
  const { userInfo } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [logoutApiCall] = useLogoutMutation()

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap()
      dispatch(logout())
      navigate('/login')
    } catch (err) {
      console.log(err)
    }
  }

  const menuHandler = (e) => {
    setIsMenuActive((current) => !current)
  }

  useEffect(() => {
    const navScroll = () => {
      if (w < 960) {
        window.scrollY > 10
          ? setNavColor('#ffffff')
          : setNavColor('transparent')
      } else {
        window.scrollY > 10
          ? setNavDesktopColor('#e0e5ec')
          : setNavDesktopColor('#e0e5ec')
      }
    }
    window.addEventListener('scroll', navScroll)
    return () => {
      window.removeEventListener('scroll', navScroll)
    }
  }, [w])

  return (
    <>
      <div
        id='overlay'
        className={isMenuActive ? 'overlay-show-admin' : ''}
      ></div>
      <div
        id='mobile-menu'
        className={
          isMenuActive
            ? 'mobile-main-menu-admin show-menu'
            : 'mobile-main-menu-admin'
        }
      >
        <ul>
          {userInfo ? (
            <>
              {userInfo.isAdmin && (
                <>
                  <li className='mobile-only'>
                    <Link to='/admin/dashboard'>Dashboard</Link>
                  </li>
                  <li className='mobile-only'>
                    <Link to='/admin/inbox'>Messages</Link>
                  </li>
                </>
              )}
              <li className='mobile-only'>
                <Link onClick={logoutHandler}>Logout</Link>
              </li>
              <li className='mobile-only'>
                <Link to='/profile'>Profile</Link>
              </li>
            </>
          ) : (
            <li className='mobile-only'>
              <Link to='/login'>Login</Link>
            </li>
          )}
          {userInfo.isOwner ? (
            <li>
              <Link to='/admin/userlist'>Users</Link>
            </li>
          ) : (
            <li>
              <Link to=''>Customers</Link>
            </li>
          )}
          {userInfo.isOwner && (
            <>
              <li>
                <Link to='/admin/translatorlist'>Translators</Link>
              </li>
              <li>
                <Link to='/admin/servicelist'>Services</Link>
              </li>
            </>
          )}
          <li>
            <Link to='/admin/complete'>Completed</Link>
          </li>
        </ul>
      </div>
      <header
        className='main-header dark'
        style={
          w < 960
            ? { backgroundColor: navColor }
            : { backgroundColor: navDesktopColor }
        }
      >
        <div className='logo'>
          <Link to='/'>
            <img src={logo_dark} alt='CTS' />
          </Link>
        </div>
        <nav className='desktop-main-menu'>
          <ul>
            {userInfo ? (
              <>
                {userInfo.isAdmin && (
                  <li className='desktop'>
                    <Link to='/admin/dashboard'>Dashboard</Link>
                  </li>
                )}
                <li className='desktop'>
                  <Link to='/admin/inbox'>Messages</Link>
                </li>
                <li className='desktop'>
                  <Link onClick={logoutHandler}>Logout</Link>
                </li>
              </>
            ) : (
              <li className='desktop'>
                <a href='/login'>Login</a>
              </li>
            )}
          </ul>
        </nav>
      </header>

      <button
        id='menu-btn'
        className={
          isMenuActive ? 'hamburger-dark open-admin' : 'hamburger-dark'
        }
        onClick={menuHandler}
        type='button'
      >
        <span className='hamburger-top'></span>
        <span className='hamburger-middle'></span>
        <span className='hamburger-bottom'></span>
      </button>
    </>
  )
}

export default HeaderDarkAdmin
