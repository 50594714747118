import { useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import FormContainer from '../../components/FormContainer'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import {
  useGetRequestDetailsQuery,
  useUpdateRequestMutation,
  useUploadTranslationMutation,
  useReturnRequestMutation
} from '../../slices/requestsApiSlice'
import { useGetUsersQuery } from '../../slices/usersApiSlice'
import Notes from '../../components/Notes'
import { toast } from 'react-toastify'
import Status from '../../components/Status'
import Icon from '../../components/Icon'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { FaCloudUploadAlt } from 'react-icons/fa'
import Customer from '../../components/Customer'
import Documents from '../../components/Documents'

const ReviewScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: requestId } = useParams()
  const navigate = useNavigate()

  const [translation, setTranslation] = useState('')

  const {
    data: request,
    isLoading,
    error,
    refetch
  } = useGetRequestDetailsQuery(requestId)
  const [updateRequest, { isloading: loadingRequest }] =
    useUpdateRequestMutation()
  const { data: users } = useGetUsersQuery()
  const [uploadTranslation] = useUploadTranslationMutation()
  const [returnRequest] = useReturnRequestMutation()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateRequest({
        requestId,
        translation,
        isReviewed: true,
        reviewedAt: Date.now()
      }).unwrap()
      refetch()
      toast.success('Ready for delivery or pickup.')
      navigate(`/admin/delivery/${requestId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const returnHandler = async (e) => {
    e.preventDefault()
    try {
      await returnRequest({
        requestId,
        translation
      }).unwrap()
      refetch()
      toast.info('Request has been returned.')
      navigate(`/admin/translates/${requestId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('translation', e.target.files[0])
    try {
      const res = await uploadTranslation(formData).unwrap()
      toast.success(res.message)
      setTranslation(res.translation)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='review status'>
            <Link to='/admin/review'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate review />
                <div className='p-4'>
                  <Customer customer={request} />
                  <hr />
                  <div className='status-translator'>
                    <h5>Translator</h5>
                    <div className='status-translator-current'>
                      {users.filter(
                        (user) => user._id === request.translator
                      )[0].firstName +
                        ' ' +
                        users.filter(
                          (user) => user._id === request.translator
                        )[0].lastName}
                    </div>
                    <div className='status-details'>
                      <small>Details</small>
                      <div>{request.details}</div>
                    </div>
                    <Documents request={request} />
                    <FormContainer className='status-translation'>
                      <h5>Actions</h5>
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='translation' className='mb-2'>
                          <small>Upload Reviewed Document</small>
                          <Form.Control
                            type='file'
                            label='Choose Document'
                            onChange={uploadFileHandler}
                            className='custom-field'
                          ></Form.Control>
                        </Form.Group>
                        <div className='duo mt-4'>
                          <Link className='btn alt-btn' onClick={returnHandler}>
                            RETURN
                          </Link>
                          <button
                            type='submit'
                            className='btn alt-btn'
                            disabled={isLoading}
                          >
                            CONTINUE
                          </button>
                        </div>
                      </Form>
                    </FormContainer>
                  </div>
                  <Notes requestId={requestId} />
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='review status'>
            {loadingRequest && <Loader />}
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <h3 className='status-title'>
                  {moment(request.createdAt).format('DD MMM YYYY')} {' at '}
                  {moment(request.createdAt).format('H:mm')}
                </h3>
                <Status request quote payment translate review />
                <div className='status-type'>
                  <Customer customer={request} />
                  <div className='status-type-data'>
                    <h5>{request.requestItem}</h5>
                    {request.totalPrice ? (
                      <p>${request.totalPrice}</p>
                    ) : (
                      <p>Pending</p>
                    )}
                  </div>
                  <Icon requestItem={request.requestItem} />
                </div>
                <div className='status-translator status-review'>
                  <h5>Translator</h5>
                  <div className='status-translator-current'>
                    {users.filter((user) => user._id === request.translator)[0]
                      .firstName +
                      ' ' +
                      users.filter((user) => user._id === request.translator)[0]
                        .lastName}
                  </div>
                  <div className='status-translator-info'>
                    <div className='status-translator-info-details'>
                      <div className='status-translator-info-details-title'>
                        Details
                      </div>
                      <p>{request.details}</p>
                    </div>
                    <div className='status-translator-info-right'>
                      <form
                        className='status-form translationForm mt-0'
                        onSubmit={submitHandler}
                      >
                        <div className='label'>Upload Reviewed Document</div>
                        <label htmlFor='translation' className='uploadFile'>
                          <FaCloudUploadAlt className='cloud' />
                        </label>
                        <input
                          type='file'
                          id='translation'
                          name='translation'
                          accept='.pdf'
                          onChange={uploadFileHandler}
                        />
                        <button id='submitTranslation' disabled={returnHandler}>
                          RETURN
                        </button>
                        <button type='submit' id='continue' name='continue'>
                          CONTINUE
                        </button>
                      </form>
                    </div>
                  </div>
                  <Documents request={request} />
                  <div className='actions'>
                    <h5>Actions</h5>
                    <div className='btn-container'>
                      <label
                        htmlFor='submitTranslation'
                        className='submitTranslation alt-btn'
                      >
                        <span>RETURN</span>
                      </label>
                      <label
                        htmlFor='continue'
                        className='alt-btn'
                        disabled={isLoading}
                      >
                        <span>CONTINUE</span>
                      </label>
                    </div>
                  </div>
                </div>
                <Notes requestId={requestId} />
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default ReviewScreen
