import { Link, useParams } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetUserDetailsQuery } from '../../slices/usersApiSlice'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import moment from 'moment'

const TranslatorScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: userId } = useParams()
  const { data: user, isLoading, error } = useGetUserDetailsQuery(userId)
  const { data: requests, isLoading: loadingRequests } = useGetRequestsQuery()

  const requestArr = requests || []
  const orders = requestArr.filter(
    (order) => order.translator === userId && !order.isCompleted
  )

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='completes list'>
            <Link to='/admin/translatorlist'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : (
              <h1 className='text-center deliveries-title'>{user.firstName}</h1>
            )}
            <div className='p-4'>
              {loadingRequests ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                <>
                  <div className='header'>
                    <div>Name</div>
                    <div>Request</div>
                  </div>
                  {[...orders].reverse().map((data) => (
                    <div key={data._id} className='list-data no-border'>
                      <Link
                        className='list-data-split'
                        to={
                          data.isDelivered
                            ? `/admin/complete/${data._id}`
                            : data.isReviewed
                            ? `/admin/delivery/${data._id}`
                            : data.isTranslated
                            ? `/admin/review/${data._id}`
                            : data.isPaid
                            ? `/admin/translate/${data._id}`
                            : data.isQuoted
                            ? `/admin/payment/${data._id}`
                            : `/admin/request/${data._id}/quote`
                        }
                      >
                        <div className='list-data-split-left'>
                          <h3 className='row-1'>
                            {data.lastName}, {data.firstName}
                          </h3>
                          <div className='row-2 small-text'>
                            {data.requestItem}
                          </div>
                        </div>
                        <div className='list-data-split-right'>
                          <h3 className='row-1'>
                            {moment(data.createdAt).format('DD MMM, YYYY')}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='translator list'>
            <Link to='/admin/translatorlist'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            {isLoading ? (
              <Loader />
            ) : (
              <h3 className='list-title'>{user.firstName}</h3>
            )}
            {loadingRequests ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                {[...orders].reverse().map((data) => (
                  <div key={data._id} className='list-data'>
                    <Link
                      className='list-data-wrapper'
                      to={
                        data.isDelivered
                          ? `/admin/complete/${data._id}`
                          : data.isReviewed
                          ? `/admin/delivery/${data._id}`
                          : data.isTranslated
                          ? `/admin/review/${data._id}`
                          : data.isPaid
                          ? `/admin/translate/${data._id}`
                          : data.isQuoted
                          ? `/admin/payment/${data._id}`
                          : `/admin/request/${data._id}/quote`
                      }
                    >
                      <div className='list-data-left'>
                        <h3>
                          {data.lastName}, {data.firstName}
                        </h3>
                        <span>{data.requestItem}</span>
                      </div>
                      <div className='list-data-right'>
                        <h3>{moment(data.createdAt).format('DD MMM, YYYY')}</h3>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default TranslatorScreen
