import React from 'react'
import { Link } from 'react-router-dom'
import DarkHeaderAdmin from '../../components/HeaderDarkAdmin'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { MdAdd } from 'react-icons/md'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
  useGetServicesQuery,
  useCreateServiceMutation
} from '../../slices/servicesApiSlice'
import { toast } from 'react-toastify'

const ServiceListScreen = () => {
  const { data: services, isLoading, error, refetch } = useGetServicesQuery()

  const [createService, { isLoading: loadingCreate }] =
    useCreateServiceMutation()

  const createServiceHandler = async () => {
    if (window.confirm('Are you sure you want to create a new service?')) {
      try {
        await createService()
        refetch()
      } catch (err) {
        toast.error(err?.data?.message || err.error)
      }
    }
  }

  return (
    <>
      <DarkHeaderAdmin />
      <section className='services list'>
        <div className='top-links px-4'>
          <h3>
            <Link to='/admin/dashboard'>
              <MdOutlineArrowBackIosNew />
            </Link>
          </h3>
          <h1 className='services-title'>SERVICES</h1>
          <h3>
            <Link onClick={createServiceHandler}>
              <MdAdd />
            </Link>
          </h3>
        </div>
        <div className='p-4'>
          {loadingCreate && <Loader />}
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <>
              {services.map((service) => (
                <div key={service._id} className='list-data'>
                  <Link
                    to={`/admin/service/${service._id}/edit`}
                    className='list-data-info'
                  >
                    <h3 className='list-data-info-name'>{service.name}</h3>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default ServiceListScreen
