import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import Icon from '../../components/Icon'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

const AdminInboxScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data, isLoading, error } = useGetRequestsQuery()

  const requestArr = data || []

  const requests = requestArr.filter((request) => !request.isCompleted)

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='profile inbox'>
            <Link to='/admin/dashboard'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='text-center profile-title'>MESSAGES</h1>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <div className='p-4'>
                <div className='mt-4 inbox-data'>
                  {[...requests].reverse().map((request) => (
                    <div key={request._id}>
                      <Link to={`/profile/inbox/${request._id}/messages`}>
                        <div className='inbox-data-entry'>
                          <Icon requestItem={request.requestItem} />
                          <div className='info'>
                            {!request.adminViewed ? (
                              <h4 className='unread'>
                                {request.lastName}, {request.firstName}
                              </h4>
                            ) : (
                              <h4>
                                {request.lastName}, {request.firstName}
                              </h4>
                            )}
                            <small className='small-text'>
                              Requested:{' '}
                              {moment(request.createdAt).format('MMM DD, YYYY')}
                            </small>
                          </div>
                        </div>
                        <hr />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='list inbox'>
            <h3 className='list-title'>MESSAGES</h3>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                {[...requests].reverse().map((request) => (
                  <div key={request._id} className='list-data'>
                    <Link
                      to={`/profile/inbox/${request._id}/messages`}
                      className='list-data-wrapper'
                    >
                      <div className='list-data-left'>
                        <div className='flex'>
                          <Icon requestItem={request.requestItem} />
                          <h3>
                            {request.lastName}, {request.firstName}
                          </h3>
                        </div>
                      </div>
                      <div className='list-inbox-right'>
                        <h3 className='list-inbox-right-date'>
                          {moment(data.createdAt).format('DD MMM, YYYY')}
                        </h3>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default AdminInboxScreen
