import { Container } from 'react-bootstrap'
import logo from '../assets/logo.png'
import { FaFacebook, FaLine, FaYelp } from 'react-icons/fa'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <Container>
        <div className='p-4'>
          <h1>CONTACT US</h1>
          <div className='footer-info'>
            <div className='footer-info-list'>
              <ul className='footer-info-list-title'>
                <li>
                  <span>EMAIL</span>
                </li>
                <li>
                  <span>PHONE</span>
                </li>
                <li>
                  <span>LOCATION</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span>support@centraltranslation.com</span>
                </li>
                <li>
                  <span>050-6872-6649</span>
                </li>
                <li>
                  <span>Kadena Air Base USO Building 337</span>
                </li>
              </ul>
            </div>
            <img src={logo} alt='CTS' />
          </div>
          <div className='icons'>
            <a
              href='https://www.facebook.com/centraltranslation'
              target='_blank'
              rel='noreferrer'
            >
              <FaFacebook />
            </a>
            <a href='https://lin.ee/TmX60Aw' target='_blank' rel='noreferrer'>
              <FaLine />
            </a>
            <a
              href='https://www.yelp.com/biz/central-translation-services-%E6%B2%96%E7%B8%84%E5%B8%82?osq=central+translations'
              target='_blank'
              rel='noreferrer'
            >
              <FaYelp />
            </a>
          </div>
          <p className='pt-4 text-center'>
            &copy; {currentYear}. Central Translation Services
          </p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
