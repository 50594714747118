import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Title from '../../components/Title'

const TechnicalScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title />

      <section className='service-items'>
        {w < 960 ? (
          <div id='title' className='mt-3'>
            <span className='top-line screens '>Technical</span>
          </div>
        ) : (
          <div className='service-dekstop'>
            <div className='service-desktop-title'>
              <div className='service-desktop-title-left'>
                <span className='upper-text'>Technical</span>
              </div>
              <div className='service-desktop-title-right'>
                <Link className='btn' to='/submitrequest'>
                  REQUEST FORM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className='container px-4'>
          <h1 className='pb-4'>Technical Document Translations</h1>
          <p>
            Vehicle titles, export certificates, repair quotations, yacht
            manuals, and other registration and certificate documents.
          </p>
          <h2 className='pt-5'>Office/Over the Phone</h2>
          <p>
            We’ll be your bilingual assistant for purchasing a vehicle,
            discussing payment plans with dealers, booking on Japanese websites,
            from the little details to important projects, we will gladly assist
            you!
          </p>
        </div>
        <div className='btn-container'>
          <Link className='btn' to='/submitrequest'>
            REQUEST FORM
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default TechnicalScreen
