import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import FormContainer from '../../components/FormContainer'
import { toast } from 'react-toastify'
import {
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useDeleteUserMutation
} from '../../slices/usersApiSlice'

const UserEditScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: userId } = useParams()
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isTranslator, setIsTranslator] = useState(false)
  const [isContractor, setIsContractor] = useState(false)

  const {
    data: user,
    isLoading,
    error,
    refetch
  } = useGetUserDetailsQuery(userId)
  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()

  const navigate = useNavigate()

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await updateUser({
        userId,
        firstName,
        middleName,
        lastName,
        email,
        isAdmin,
        isTranslator,
        isContractor
      })
      toast.success('User updated successfully')
      refetch()
      navigate(`/admin/user/${userId}`)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setMiddleName(user.middleName)
      setLastName(user.lastName)
      setEmail(user.email)
      setIsAdmin(user.isAdmin)
      setIsTranslator(user.isTranslator)
      setIsContractor(user.isContractor)
    }
  }, [user])

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure?')) {
      try {
        await deleteUser(id)
        toast.success('User deleted')
        navigate('/admin/userlist')
      } catch (err) {
        toast.error(err?.data?.message || err.error)
      }
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='user-edit edit'>
            <FormContainer>
              <div className='p-4'>
                <Link to={`/admin/user/${userId}`}>
                  <h3>
                    <MdOutlineArrowBackIosNew className='back-btn' />
                  </h3>
                </Link>
                <h1 className='edit-title'>EDIT USER</h1>
                {isLoading ? (
                  <Loader />
                ) : error ? (
                  <Message variant='danger'>
                    {error?.data?.message || error.error}
                  </Message>
                ) : (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId='firstName' className='my-3'>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='FIRST NAME'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='middleName' className='my-3'>
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='MIDDLE NAME'
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='lastName' className='my-3'>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='LAST NAME'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='email' className='my-3'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='EMAIL'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId='isAdmin'
                      className='my-3 status-group'
                    >
                      <Form.Check
                        type='checkbox'
                        checked={isAdmin}
                        onChange={(e) => setIsAdmin(e.target.checked)}
                      ></Form.Check>
                      <div className='status-group-label'>Admin</div>
                    </Form.Group>
                    <Form.Group
                      controlId='isTranslator'
                      className='my-3 status-group'
                    >
                      <Form.Check
                        type='checkbox'
                        checked={isTranslator}
                        onChange={(e) => setIsTranslator(e.target.checked)}
                      ></Form.Check>
                      <div className='status-group-label'>Translator</div>
                    </Form.Group>
                    <Form.Group
                      controlId='isContractor'
                      className='my-3 status-group'
                    >
                      <Form.Check
                        type='checkbox'
                        checked={isContractor}
                        onChange={(e) => setIsContractor(e.target.checked)}
                      ></Form.Check>
                      <div className='status-group-label'>Contractor</div>
                    </Form.Group>
                    <button
                      type='submit'
                      className='button'
                      disabled={isLoading}
                    >
                      UPDATE
                    </button>
                    <button
                      className='button delete'
                      onClick={() => deleteHandler(user._id)}
                    >
                      DELETE
                    </button>
                  </Form>
                )}
              </div>
            </FormContainer>
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='user'>
            <Link to={`/admin/user/${userId}`}>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='user-title'>EDIT USER</h1>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <form className='userForm' onSubmit={submitHandler}>
                <div className='userForm-name'>
                  <div className='userForm-name-input'>
                    <label className='label' htmlFor='firstName'>
                      First Name
                    </label>
                    <input
                      type='text'
                      name='firstName'
                      id='firstName'
                      placeholder='FIRST NAME'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    ></input>
                  </div>
                  <div className='userForm-name-input'>
                    <label className='label' htmlFor='middleName'>
                      Middle Name
                    </label>
                    <input
                      type='text'
                      name='middleName'
                      id='middleName'
                      placeholder='MIDDLE NAME'
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    ></input>
                  </div>
                  <div className='userForm-name-input'>
                    <label className='label' htmlFor='lastName'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      name='lastName'
                      id='lastName'
                      placeholder='LAST NAME'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    ></input>
                  </div>
                </div>
                <div className='userForm-status mt-4'>
                  <div className='userForm-status-input'>
                    <label className='label' htmlFor='email'>
                      Email
                    </label>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      placeholder='EMAIL'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></input>
                  </div>
                  <label
                    htmlFor='isAdmin'
                    className='userForm-status-input label checkbox-label'
                  >
                    Admin
                    <input
                      type='checkbox'
                      name='isAdmin'
                      id='isAdmin'
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                    ></input>
                    <span className='checkmark'></span>
                  </label>
                  <label
                    htmlFor='isTranslator'
                    className='userForm-status-input label checkbox-label'
                  >
                    Translator
                    <input
                      type='checkbox'
                      name='isTranslator'
                      id='isTranslator'
                      checked={isTranslator}
                      onChange={(e) => setIsTranslator(e.target.checked)}
                    ></input>
                    <span className='checkmark'></span>
                  </label>
                  <label
                    htmlFor='isContractor'
                    className='userForm-status-input label checkbox-label'
                  >
                    Contractor
                    <input
                      type='checkbox'
                      name='isContractor'
                      id='isContractor'
                      checked={isContractor}
                      onChange={(e) => setIsContractor(e.target.checked)}
                    ></input>
                    <span className='checkmark'></span>
                  </label>
                </div>
                <div className='actions'>
                  <p className='label'>Actions</p>
                  <div className='btn-container'>
                    <button
                      type='submit'
                      className='button'
                      disabled={isLoading}
                    >
                      <span>UPDATE</span>
                    </button>
                    <button
                      className='button delete'
                      onClick={() => deleteHandler(user._id)}
                    >
                      <span>DELETE</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default UserEditScreen
