import React from 'react'
import { Link } from 'react-router-dom'
import default_bg from '../assets/default_bg.png'

const Title = ({ image }) => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <div className='title'>
        {w < 500 ? (
          <div className='mobile-only'>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
            <Link className='btn-primary' to='/submitrequest'>
              REQUEST FORM
            </Link>
          </div>
        ) : image ? (
          <div className='desktop'>
            <div className='overlay'></div>
            <img src={image} alt='main' />
          </div>
        ) : (
          <div className='desktop'>
            <img src={default_bg} alt='default' />
          </div>
        )}
      </div>
    </>
  )
}

export default Title
