import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import marriage from '../assets/marriage.png'
import military from '../assets/military.png'
import clock from '../assets/clock.png'
import penguins from '../assets/penguins.png'
import one_stop from '../assets/one_stop.png'
import marriage_desktop from '../assets/marriage_desktop.png'

const MilitaryScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title image={military} />
      {/* Mobile */}
      {w < 960 ? (
        <section className='military'>
          <div id='title' className='mt-3'>
            <span className='top-line'>Military Customers</span>
          </div>
          <div className='container px-4'>
            <h3>WELCOME</h3>
            <h1>
              CONVENIENT <br />
              ON-BASE OFFICE!
            </h1>
            <p>
              We are located inside the USO, building 337 on Kadena Air Base by
              Gate 2. Being on the military base, there is plenty of parking
              available and we offer the most convenient proximity to Okinawa
              City Office, Legal Office, and other government buildings to
              manage your administrative tasks quickly.
            </p>
            <h1 className='pt-4'>NO EXPEDITED FEE!</h1>
            <p>
              Our services are tailored to the busy and unpredictable lifestyles
              of our military customers. We charge no extra fee to arrange your
              requested turnaround time whether that’s the same day or the next
              day for most services.
            </p>
            <h1 className='pt-4'>WALK-INS WELCOMED!</h1>
            <p>
              Monday - Friday! Our office only closes on American national
              holidays and weekends. Even outside of our business hours, you can
              still place an order online, via email, or by phone!
            </p>
            <h1 className='pt-4'>ONE STOP SERVICE!</h1>
            <p>
              For our busy customers, our original design Konin-Todoke (Marriage
              notification form in Japan) is readily available. No more going
              back and forth to the city hall!
            </p>
          </div>
          <div className='container px-4 lt-blue-bg'>
            <h1 className='pt-4'>GENERAL MARRIAGE PROCESS</h1>
            <img src={marriage} alt='marriage' className='my-3' />
            <div className='marriage-steps'>
              <div className='step-number'>1</div>
              <div className='step-title'>Marriage Affidavit</div>
              <div className='step-info'>
                <p>
                  Obtain a Certificate of Competency to Marry from your
                  country’s embassy with your full name, including your middle
                  name.
                </p>
                <h5>1.1 SOFA</h5>
                <p>
                  If both parties are SOFA status, you may request for a joint
                  affidavit for marriage at the base legal office.
                </p>
                <h5>1.2 NON-SOFA</h5>
                <p>
                  Non-SOFA non-Japanese citizens may request a joint/single
                  affidavit or Certificate of Competency to Marry from your home
                  country’s embassy.
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>2</div>
              <div className='step-title two-rows'>
                Passport/Birth Certificate
              </div>
              <div className='step-info'>
                <p>
                  Either the birth certificate or passport must be submitted to
                  the city hall for your identification. Birth certificates must
                  be translated but passports may not required to be translated
                  depends on the rules of your region’s city hall.
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>3</div>
              <div className='step-title'>Place Your Order</div>
              <div className='step-info'>
                <p>
                  Bring all the documents to our Kadena office! We will give you
                  a quote on the spot. Konin-todoke (marriage notification form)
                  is available if you’d like to simplify the process.
                </p>
                <p>
                  If you cannot visit our office, you can place your order via
                  email. Send a PDF or photos of the documents. We’ll get back
                  to you with a quote!
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>4</div>
              <div className='step-title'>Turnaround Time</div>
              <div className='step-info'>
                <p>
                  Generally the next-day or two-day is available, but if you’d
                  like your order expedited, we can arrange same-day service at
                  no charge. Please reach out to us before 10:30 AM if you need
                  it ASAP.
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>5</div>
              <div className='step-title'>Witnesses</div>
              <div className='step-info'>
                <p>
                  While we translate your documents, please prepare two
                  witnesses for your marriage.
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>6</div>
              <div className='step-title'>City Office</div>
              <div className='step-info'>
                <p>
                  Once you have the translations, Konin-todoke, and all the
                  necessary documents, submit them to the city hall for
                  approval.
                </p>
              </div>
            </div>
            <div className='marriage-steps'>
              <div className='step-number'>7</div>
              <div className='step-title'>Congratulations!</div>
              <div className='step-info'>
                <p>
                  You will receive a phone call from the city office once your
                  application has been processed. Congratulations! Now you’re
                  legally married under Japanese law!
                </p>
                <p>
                  Typically within 3 days, you will be able to obtain a marriage
                  certificate at the city hall in Japanese. You can drop it off
                  or place your order via email for translation!
                </p>
              </div>
            </div>
            <div className='marriage-steps mb-0'>
              <div className='step-number'>8</div>
              <div className='step-title two-rows'>
                Recognition by Your Country
              </div>
              <div className='step-info'>
                <p className='mb-0 pb-3'>
                  With the marriage certificate and its translation completed,
                  proceed to get your marriage recognized in your home country
                  as the final step!
                </p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className='military'>
          <div className='military-title'>
            <div className='military-title-left'>
              <span className='upper-text'>
                Military <br />
                Customers
              </span>
            </div>
            <div className='military-title-right'>
              <Link className='btn' to='/submitrequest'>
                REQUEST FORM
              </Link>
            </div>
          </div>

          <div className='container px-4'>
            <div className='military-welcome'>
              <h1>WELCOME</h1>
              <h2>CONVENIENT ON-BASE OFFICE!</h2>
            </div>
            <p>
              We are located inside the USO, building 337 on Kadena Air Base by
              Gate 2. Being on the military base, there is plenty of parking
              available and we offer the most convenient proximity to Okinawa
              City Office, Legal Office, and other government buildings to
              manage your administrative tasks quickly.
            </p>

            <div className='military-block'>
              <img src={clock} alt='clock' />
              <div>
                <h2>NO EXPEDITED FEE!</h2>
                <p>
                  Our services are tailored to the busy and unpredictable
                  lifestyles of our military customers. We charge no extra fee
                  to arrange your requested turnaround time whether that’s the
                  same day or the next day for most services.
                </p>
              </div>
            </div>
            <div className='military-block'>
              <div>
                <h2>WALK-INS WELCOMED!</h2>
                <p>
                  Monday - Friday! Our office only closes on American national
                  holidays and weekends. Even outside of our business hours, you
                  can still place an order online, via email, or by phone!
                </p>
              </div>
              <img src={penguins} alt='penguins' />
            </div>
            <div className='military-block'>
              <img src={one_stop} alt='one_stop' />
              <div>
                <div>
                  <h2>ONE STOP SERVICE!</h2>
                  <p>
                    For our busy customers, our original design Konin-Todoke
                    (Marriage notification form in Japan) is readily available.
                    No more going back and forth to the city hall!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='military-marriage mt-5'>
            <div className='military-marriage-block'>
              <h1 className='military-marriage-block-title'>
                GENERAL MARRIAGE PROCESS
              </h1>
              <img src={marriage_desktop} alt='marriage' />
            </div>
            <div className='lt-blue-bg'>
              <div className='marriage-steps'>
                <div className='step-number'>1</div>
                <div className='step-title'>Marriage Affidavit</div>
                <div className='step-info'>
                  <p>
                    Obtain a Certificate of Competency to Marry from your
                    country’s embassy with your full name, including your middle
                    name.
                  </p>
                  <h5>1.1 SOFA</h5>
                  <p>
                    If both parties are SOFA status, you may request for a joint
                    affidavit for marriage at the base legal office.
                  </p>
                  <h5>1.2 NON-SOFA</h5>
                  <p>
                    Non-SOFA non-Japanese citizens may request a joint/single
                    affidavit or Certificate of Competency to Marry from your
                    home country’s embassy.
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>2</div>
                <div className='step-title'>Passport/Birth Certificate</div>
                <div className='step-info'>
                  <p>
                    Either the birth certificate or passport must be submitted
                    to the city hall for your identification. Birth certificates
                    must be translated but passports may not required to be
                    translated depends on the rules of your region’s city hall.
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>3</div>
                <div className='step-title'>Place Your Order</div>
                <div className='step-info'>
                  <p>
                    Bring all the documents to our Kadena office! We will give
                    you a quote on the spot. Konin-todoke (marriage notification
                    form) is available if you’d like to simplify the process. If
                    you cannot visit our office, you can place your order via
                    email. Send a PDF or photos of the documents. We’ll get back
                    to you with a quote!
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>4</div>
                <div className='step-title'>Turnaround Time</div>
                <div className='step-info'>
                  <p>
                    Generally the next-day or two-day is available, but if you’d
                    like your order expedited, we can arrange same-day service
                    at no charge. Please reach out to us before 10:30 AM if you
                    need it ASAP.
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>5</div>
                <div className='step-title'>Witnesses</div>
                <div className='step-info'>
                  <p>
                    While we translate your documents, please prepare two
                    witnesses for your marriage.
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>6</div>
                <div className='step-title'>City Office</div>
                <div className='step-info'>
                  <p>
                    Once you have the translations, Konin-todoke, and all the
                    necessary documents, submit them to the city hall for
                    approval.
                  </p>
                </div>
              </div>
              <div className='marriage-steps'>
                <div className='step-number'>7</div>
                <div className='step-title'>Congratulations!</div>
                <div className='step-info'>
                  <p>
                    You will receive a phone call from the city office once your
                    application has been processed. Congratulations! Now you’re
                    legally married under Japanese law! Typically within 3 days,
                    you will be able to obtain a marriage certificate at the
                    city hall in Japanese. You can drop it off or place your
                    order via email for translation!
                  </p>
                </div>
              </div>
              <div className='marriage-steps mb-0'>
                <div className='step-number'>8</div>
                <div className='step-title'>Recognition by Your Country</div>
                <div className='step-info'>
                  <p className='mb-0 pb-3'>
                    With the marriage certificate and its translation completed,
                    proceed to get your marriage recognized in your home country
                    as the final step!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  )
}

export default MilitaryScreen
