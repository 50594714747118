import { Link, useParams } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { FiEdit3 } from 'react-icons/fi'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetUserDetailsQuery } from '../../slices/usersApiSlice'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import moment from 'moment'

const UserInfoScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { id: userId } = useParams()
  const { data: user, isLoading, error } = useGetUserDetailsQuery(userId)
  const { data: requests } = useGetRequestsQuery()

  const requestArr = requests || []
  const orders = requestArr.filter((order) => order.user === userId)
  const paid = orders.filter((order) => order.isPaid)
  const spent = paid.map((data) => data.totalPrice)
  let sum = 0
  for (let i = 0; i < spent.length; i++) {
    sum += spent[i]
  }

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='user'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <Link to='/admin/userlist'>
                  <h3>
                    <MdOutlineArrowBackIosNew className='back-btn' />
                  </h3>
                </Link>
                <h1 className='user-title'>
                  {user.lastName}, {user.firstName}
                </h1>
                <p className='text-center'>
                  Customer since {moment(user.createdAt).fromNow()}
                </p>
                <div className='p-4'>
                  <div className='user-stats'>
                    <div className='user-stats-spent'>
                      <p>Amount Spent</p>
                      <div className='data'>${sum}</div>
                    </div>
                    <div className='user-stats-orders'>
                      <p>Orders</p>
                      <div className='data'>{orders.length}</div>
                    </div>
                  </div>
                  <div className='user-info'>
                    <div className='user-info-name mt-4'>
                      <h2>User Information</h2>
                      <Link to={`/admin/user/${user._id}/edit`}>
                        <h4>
                          <FiEdit3 />
                        </h4>
                      </Link>
                    </div>
                    <div className='user-info-stats'>
                      <div className='data'>
                        <p>Last Name</p>
                        <div>{user.lastName}</div>
                      </div>
                      <div className='data'>
                        <p>First Name</p>
                        <div>{user.firstName}</div>
                      </div>
                      {user.middleName && (
                        <div className='data'>
                          <p>Middle Name</p>
                          <div>{user.middleName}</div>
                        </div>
                      )}
                      <div className='data'>
                        <p>Email</p>
                        <div>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </div>
                      </div>
                      <div className='data'>
                        <p>Phone</p>
                        <div>{user.phone}</div>
                      </div>
                      <div className='data'>
                        <p>Branch</p>
                        <div>{user.branch}</div>
                      </div>
                      <div className='data'>
                        {user.isAdmin && <p>Status</p>}
                        <div className='user-status'>
                          {user.isAdmin && (
                            <div className='user-status-pill'>Admin</div>
                          )}
                          {user.isTranslator && (
                            <div className='user-status-pill'>Translator</div>
                          )}
                          {user.isContractor && (
                            <div className='user-status-pill'>Contractor</div>
                          )}
                          {user.isOwner && (
                            <div className='user-status-pill'>Owner</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='user-orders'>
                    {orders.length > 0 && (
                      <>
                        <h2 className='mt-4'>Orders</h2>
                        <div className='user-orders-stats'>
                          {orders.map((order) => (
                            <div key={order._id}>
                              <Link
                                to={`/request/${order._id}`}
                                className='data'
                              >
                                <div className='data-title'>
                                  {order.requestItem}
                                </div>
                                <div className='data-date'>
                                  {moment(order.createdAt).format(
                                    'MMM DD, YYYY'
                                  )}
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='user'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <Link to='/admin/userlist'>
                  <h3>
                    <MdOutlineArrowBackIosNew className='back-btn' />
                  </h3>
                </Link>
                <h3 className='user-title'>
                  {user.lastName}, {user.firstName}
                </h3>
                <p className='text-center mb-5'>
                  Customer since {moment(user.createdAt).fromNow()}
                </p>
                <div className='user-stats'>
                  <div className='user-stats-spent'>
                    <p>Amount Spent</p>
                    <div className='data'>${sum}</div>
                  </div>
                  <div className='user-stats-orders'>
                    <p>Orders</p>
                    <div className='data'>{orders.length}</div>
                  </div>
                </div>
                <div className='user-info'>
                  <div className='user-info-title'>
                    <h3>User Information</h3>
                    <Link to={`/admin/user/${user._id}/edit`}>
                      <h3>
                        <FiEdit3 />
                      </h3>
                    </Link>
                  </div>
                  <div className='user-info-stats'>
                    <div className='user-info-stats-name'>
                      <div>
                        <p>Last Name</p>
                        <div>{user.lastName}</div>
                      </div>
                      <div>
                        <p>First Name</p>
                        <div>{user.firstName}</div>
                      </div>
                      {user.middleName && (
                        <div>
                          <p>Middle Name</p>
                          <div>{user.middleName}</div>
                        </div>
                      )}
                    </div>
                    <div className='user-info-stats-contact'>
                      <div>
                        <p>Email</p>
                        <div>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </div>
                      </div>
                      <div>
                        <p>Phone</p>
                        <div>{user.phone}</div>
                      </div>
                      <div>
                        <p>Branch</p>
                        <div>{user.branch}</div>
                      </div>
                    </div>
                    <div className='user-info-stats-status'>
                      {user.isAdmin && <p>Status</p>}
                      <div className='data'>
                        {user.isAdmin && <div className='data-pill'>Admin</div>}
                        {user.isTranslator && (
                          <div className='data-pill'>Translator</div>
                        )}
                        {user.isContractor && (
                          <div className='data-pill'>Contractor</div>
                        )}
                        {user.isOwner && <div className='data-pill'>Owner</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='user-list'>
                  {orders.length > 0 && (
                    <>
                      <h3>Orders</h3>
                      {orders.map((order) => (
                        <div key={order._id} className='user-list-data mt-2'>
                          <Link
                            to={`/request/${order._id}`}
                            className='user-list-data-wrapper'
                          >
                            <h4 className='user-list-data-left'>
                              {order.requestItem}
                            </h4>
                            <h4 className='user-list-data-right'>
                              {moment(order.createdAt).format('MMM DD, YYYY')}
                            </h4>
                          </Link>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default UserInfoScreen
