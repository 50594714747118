import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import HeaderDark from '../components/HeaderDark'
import { Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import { useLoginMutation } from '../slices/usersApiSlice'
import { setCredentials } from '../slices/authSlice'
import { toast } from 'react-toastify'
import logo_dark from '../assets/logo_dark.png'

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [login, { isLoading }] = useLoginMutation()

  const { userInfo } = useSelector((state) => state.auth)

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const redirect = sp.get('redirect') || '/'

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, redirect, navigate])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await login({ email, password }).unwrap()
      dispatch(setCredentials({ ...res }))
      navigate(redirect)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  return (
    <>
      <div className='mobile'>
        <HeaderDark />
        <FormContainer>
          <h1 className='text-center login-title mt-5'>LOGIN</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email' className='my-3'>
              <Form.Control
                type='email'
                placeholder='EMAIL'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='password' className='my-3'>
              <Form.Control
                type='password'
                placeholder='PASSWORD'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <button type='submit' className='button' disabled={isLoading}>
              SIGN IN
            </button>
          </Form>

          <Row className='register'>
            <Col>
              NOT A MEMBER?{' '}
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
              >
                REGISTER
              </Link>
            </Col>
          </Row>
          {isLoading && <Loader />}
        </FormContainer>
      </div>

      {/* Desktop */}
      <div className='desktop'>
        <div className='login-container' id='container'>
          <FormContainer>
            <Link to='/'>
              <img src={logo_dark} alt='CTS' />
            </Link>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='email' className='my-3'>
                <Form.Control
                  type='email'
                  placeholder='EMAIL'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='password' className='my-3'>
                <Form.Control
                  type='password'
                  placeholder='PASSWORD'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <button type='submit' className='button' disabled={isLoading}>
                SIGN IN
              </button>
            </Form>
          </FormContainer>
          <div className='overlay-container'>
            <div className='overlay title'>
              <div id='stars'></div>
              <div id='stars2'></div>
              <div id='stars3'></div>
              <div className='overlay-panel overlay-right'>
                <h1>Not a Member?</h1>
                <Link
                  to={redirect ? `/register?redirect=${redirect}` : '/register'}
                  className='register-btn'
                >
                  CREATE AN ACCOUNT
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
