import React from 'react'
import { Link } from 'react-router-dom'
import DarkHeaderAdmin from '../../components/HeaderDarkAdmin'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetUsersQuery } from '../../slices/usersApiSlice'

const UserListScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data: users, isLoading, error } = useGetUsersQuery()

  return (
    <>
      <DarkHeaderAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='users list'>
            <Link to='/admin/dashboard'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='users-title'>USERS</h1>
            <div className='p-4'>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>
                  {error?.data?.message || error.error}
                </Message>
              ) : (
                <>
                  {users.map((user) => (
                    <div key={user._id} className='list-data'>
                      <Link
                        to={`/admin/user/${user._id}`}
                        className='list-data-split mb-0'
                      >
                        <h3 className='name'>
                          {user.lastName}, {user.firstName}
                        </h3>
                        <div className='status-pill'>
                          {user.isAdmin && (
                            <div className='status-pill-data'>A</div>
                          )}
                          {user.isTranslator && (
                            <div className='status-pill-data'>T</div>
                          )}
                          {user.isContractor && (
                            <div className='status-pill-data'>C</div>
                          )}
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='list users'>
            <h3 className='list-title'>USERS</h3>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                {users.map((user) => (
                  <div key={user._id} className='list-data'>
                    <Link
                      to={`/admin/user/${user._id}`}
                      className='list-data-wrapper'
                    >
                      <div className='list-data-left'>
                        <h3>
                          {user.lastName}, {user.firstName}
                        </h3>
                      </div>
                      <div className='list-data-right'>
                        {user.isAdmin && (
                          <div className='list-data-right-pill'>A</div>
                        )}
                        {user.isTranslator && (
                          <div className='list-data-right-pill'>T</div>
                        )}
                        {user.isContractor && (
                          <div className='list-data-right-pill'>C</div>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default UserListScreen
