import React from 'react'
import { Nav } from 'react-bootstrap'
import icon_check_active from '../assets/icon_check_active.png'
import icon_check_disabled from '../assets/icon_check_disabled.png'
import icon_quote_active from '../assets/icon_quote_active.png'
import icon_request_active from '../assets/icon_request_active.png'
import icon_payment_active from '../assets/icon_payment_active.png'
import icon_payment_disabled from '../assets/icon_payment_disabled.png'
import icon_translate_active from '../assets/icon_translate_active.png'
import icon_translate_disabled from '../assets/icon_translate_disabled.png'
import icon_review_active from '../assets/icon_review_active.png'
import icon_review_disabled from '../assets/icon_review_disabled.png'
import icon_delivery_active from '../assets/icon_delivery_active.png'
import icon_delivery_disabled from '../assets/icon_delivery_disabled.png'

const Status = ({
  request,
  quote,
  payment,
  translate,
  review,
  delivery,
  complete
}) => {
  let w = document.documentElement.clientWidth || window.innerWidth

  if (w < 960) {
    return (
      <Nav className='status-process p-3'>
        <div>
          {request ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <div className='status-process-link-title'>Inquiry</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <div className='status-process-link-title'>Inquiry</div>
            </div>
          )}
        </div>
        <div>
          {quote ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Quote</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Quote</div>
            </div>
          )}
        </div>
        <div>
          {payment ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Payment</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Payment</div>
            </div>
          )}
        </div>
        <div>
          {translate ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Translate</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Translate</div>
            </div>
          )}
        </div>
        <div>
          {review ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Review</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Review</div>
            </div>
          )}
        </div>
        <div>
          {delivery ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Delivery Pickup</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Delivery Pickup</div>
            </div>
          )}
        </div>
        <div>
          {complete ? (
            <div className='status-process-link active'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Complete</div>
            </div>
          ) : (
            <div className='status-process-link disabled'>
              <span className='dot'></span>
              <span className='line'></span>
              <div className='status-process-link-title'>Complete</div>
            </div>
          )}
        </div>
      </Nav>
    )
  } else {
    return (
      <Nav className='status-process p-3'>
        <div>
          {request ? (
            <img
              src={icon_request_active}
              alt='check'
              className='status-process-link active'
            />
          ) : (
            <img
              src={icon_check_disabled}
              alt='check'
              className='status-process-link active'
            />
          )}
        </div>
        <div>
          {quote ? (
            <img
              src={icon_quote_active}
              alt='check'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_check_disabled}
              alt='check'
              className='status-process-link'
            />
          )}
        </div>
        <div>
          {payment ? (
            <img
              src={icon_payment_active}
              alt='payment'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_payment_disabled}
              alt='payment'
              className='status-process-link'
            />
          )}
        </div>
        <div>
          {translate ? (
            <img
              src={icon_translate_active}
              alt='translate'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_translate_disabled}
              alt='translate'
              className='status-process-link'
            />
          )}
        </div>
        <div>
          {review ? (
            <img
              src={icon_review_active}
              alt='review'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_review_disabled}
              alt='review'
              className='status-process-link'
            />
          )}
        </div>
        <div>
          {delivery ? (
            <img
              src={icon_delivery_active}
              alt='delivery'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_delivery_disabled}
              alt='delivery'
              className='status-process-link'
            />
          )}
        </div>
        <div>
          {complete ? (
            <img
              src={icon_check_active}
              alt='check'
              className='status-process-link'
            />
          ) : (
            <img
              src={icon_check_disabled}
              alt='check'
              className='status-process-link'
            />
          )}
        </div>
      </Nav>
    )
  }
}

export default Status
