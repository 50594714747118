import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Title from '../../components/Title'

const MedicalScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title />

      <section className='service-items'>
        {w < 960 ? (
          <div id='title' className='mt-3'>
            <span className='top-line screens '>Medical</span>
          </div>
        ) : (
          <div className='service-dekstop'>
            <div className='service-desktop-title'>
              <div className='service-desktop-title-left'>
                <span className='upper-text'>Medical</span>
              </div>
              <div className='service-desktop-title-right'>
                <Link className='btn' to='/submitrequest'>
                  REQUEST FORM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className='container px-4'>
          <h1 className='pb-4'>Seeing a doctor off base?</h1>
          <h2>On-Site Medical Interpretations</h2>
          <p>
            We will appoint you a designated translator for your medical visits
            and send you a report, so you can keep track of your records.
          </p>
          <h1 className='pb-2'>Need Medical Records?</h1>
          <h2>Medical Document Translations</h2>
          <p>
            Diagnosis reports, referral letters, medical treatment records,
            treatment cost statements, etc.
          </p>
          <h1 className='pb-2'>Traveling with pets?</h1>
          <h2>Pet Document Translations</h2>
          <p>
            Vaccination certificates and other diagnosis reports and
            certificates.
          </p>
        </div>
        <div className='btn-container'>
          <Link className='btn' to='/submitrequest'>
            REQUEST FORM
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default MedicalScreen
