import { useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderDark from '../components/HeaderDark'
import Loader from '../components/Loader'
import InboxMessage from '../components/InboxMessage'
import { useGetRequestDetailsQuery } from '../slices/requestsApiSlice'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import moment from 'moment'

const MessageScreen = () => {
  const { id: requestId } = useParams()
  const { data: request, isLoading } = useGetRequestDetailsQuery(requestId)
  const { userInfo } = useSelector((state) => state.auth)

  return (
    <>
      <HeaderDark />
      <section className='profile message status'>
        {userInfo.isAdmin ? (
          <Link to='/admin/inbox'>
            <h3>
              <MdOutlineArrowBackIosNew className='back-btn' />
            </h3>
          </Link>
        ) : (
          <Link to='/profile/inbox'>
            <h3>
              <MdOutlineArrowBackIosNew className='back-btn' />
            </h3>
          </Link>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='message-title'>
              <h1 className='message-title-header'>
                {request.requestItem.toUpperCase()}
              </h1>
              <p>{moment(request.createdAt).format('MMM DD, YYYY')}</p>
            </div>
            <div className='p-4'>
              <InboxMessage requestId={requestId} />
            </div>
          </>
        )}
      </section>
    </>
  )
}

export default MessageScreen
