import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Title from '../../components/Title'

const LegalScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title />

      <section className='service-items'>
        {w < 960 ? (
          <div id='title' className='mt-3'>
            <span className='top-line screens '>Legal</span>
          </div>
        ) : (
          <div className='service-dekstop'>
            <div className='service-desktop-title'>
              <div className='service-desktop-title-left'>
                <span className='upper-text'>Legal</span>
              </div>
              <div className='service-desktop-title-right'>
                <Link className='btn' to='/submitrequest'>
                  REQUEST FORM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className='container px-4'>
          <h1 className='pb-4'>LEGAL DOCUMENT TRANSLATIONS</h1>
          <p>
            Housing lease agreements, sales agreements, employment contracts,
            family law related documents such as testaments, legal contracts,
            litigation letters, and court orders.
          </p>
          <h2 className='pt-5'>COURT INTERPRETATIONS</h2>
          <p>
            District courts, police stations, detention centers, etc. located on
            the Okinawa mainland.
          </p>
          <h2 className='pt-5'>TAX & FINANCE TRANSLATIONS</h2>
          <p>
            Income certificates, income taxes, tax declaration forms, pay stubs,
            salary slips, etc.
          </p>
          <h2 className='pt-5'>OFFICIAL DOCUMENT TRANSLATIONS</h2>
          <p>
            Marriage related documents such as affidavits of marriage, birth
            certificates, family registers, resident records, driver’s licenses,
            and other official records and certificates.
          </p>
        </div>
        <div className='btn-container'>
          <Link className='btn' to='/submitrequest'>
            REQUEST FORM
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default LegalScreen
