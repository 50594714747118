import React from 'react'
import HeaderDark from '../components/HeaderDark'
import Footer from '../components/Footer'
import international from '../assets/international.png'

const AboutScreen = () => {
  return (
    <>
      <HeaderDark />
      <section className='about'>
        <h1 className='about-title'>ABOUT US</h1>
        <div className='container px-4'>
          <p className='pt-4'>
            Located inside the Kadena Air Base USO, Central Translation Services
            is the first and only translation office to open a location on US
            military bases here in Okinawa Japan. Due to our unique location and
            nature of our profession, our team members possess an
            internationally unique background and understanding of diverse
            cultural values. Their universal love for humanity drives their
            passion for helping others with personal and business endeavors.
            From assisting with daily personal challenges in life to expanding
            your business internationally, Central Translation Services is here
            to guide you every step of the way. Our goal is to help you achieve
            your goals. Our trained professionals understand our role as a
            bridge that connects two different worlds, and value
            professionalism, information protection, neutrality, and
            sensitivity.
          </p>
          <div className='about-flex'>
            <p className='quote'>
              "To connect people and businesses beyond language, cultural, and
              geographical barriers."
            </p>
            <img src={international} alt='international' className='my-3' />
          </div>
          <h1>OUR HISTORY</h1>
          <p>
            Central Translation Services was first established as a legal
            translation office with a focus on business support. One of our
            first translator is a Spanish lawyer who excels in contract writing
            and studied law in Japan, and another translator as a Japanese
            translator with a background in law studying Hague Treaty for
            international children’s rights and intellectual property law. Long
            before moving into our current main office, our mission was to
            support international business owners in Japan to manage their
            businesses smoothly and in reverse assist Japanese companies expand
            into international market safely.
          </p>
          <h1>OUR BELIEF</h1>
          <p>
            With advancements in technology we can establish connections
            regardless of distance. Artificial intelligence’s influence on
            business processes have paved the way for globalization, promoting
            language and data as vital tools for conflict resolution, exploring
            new opportunities, and ultimately bettering our lives. As opposed to
            direct word-to-word translations and interpretations, we understand
            the cultural differences and sensitivity of business environments.
            Information filtered through us will be rendered with subtle
            adjustment when necessary, depending on situations deemed
            appropriate for each case.
          </p>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AboutScreen
