import React from 'react'
import Loader from '../components/Loader'
import { useGetServicesQuery } from '../slices/servicesApiSlice'

const Icon = ({ requestItem }) => {
  const { data: services, isLoading: loadingService } = useGetServicesQuery()
  return (
    <>
      {loadingService ? (
        <Loader />
      ) : (
        services
          .filter((service) => service.name === requestItem)
          .map((i) => <img key={i._id} src={i.icon} alt='icon' />)
      )}
    </>
  )
}

export default Icon
