import React from 'react'
import axios from 'axios'
import { BASE_URL } from '../constants'

const DownloadButton = ({ filename, label }) => {
  const downloadHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}${filename}`, {
        responseType: 'blob'
      })

      const blob = new Blob([res.data], { type: res.data.type })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'cts-file'
      link.click()
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <button className='btn' onClick={downloadHandler}>
      <h5>{label}</h5>
    </button>
  )
}

export default DownloadButton
