import React from 'react'
import HeaderDark from '../components/HeaderDark'
import Footer from '../components/Footer'

const ContactScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <HeaderDark />
      <section className='contact'>
        <h1 className='contact-title'>CONTACT US</h1>
        <div className='container mt-4 px-4'>
          <div className='info'>
            {w < 960 ? (
              <>
                <ul className='info-title'>
                  <li>
                    <span>PHONE</span>
                  </li>
                  <li>
                    <span>EMAIL</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>050-6872-6649</span>
                  </li>
                  <li>
                    <span>support@centraltranslation.com</span>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <div>
                  <span className='info-title'>PHONE</span>
                  <span>050-6872-6649</span>
                </div>
                <div>
                  <span className='info-title'>EMAIL</span>
                  <span>support@centraltranslation.com</span>
                </div>
              </>
            )}
          </div>
          <p className='quote mb-5'>
            "Global Reach: online, by mail, or in-person."
          </p>
          <div className='contact-flex'>
            <div className='contact-flex-left'>
              <div>
                <h1>KADENA USO OFFICE</h1>
                <h5>KADENA AIR BASE</h5>
                <h5>BUILDING 337 APO, AP 96368</h5>
              </div>
              <div className='pt-3'>
                <h5>MONDAY - FRIDAY: 0900 - 1700</h5>
                <h5 className='mb-0'>LUNCH TIME: 1300 - 1400</h5>
                <small>(CLOSED: SATURDAY, SUNDAY, AND AMERICAN HOLIDAYS)</small>
              </div>
            </div>
            <div className='contact-flex-right'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d446.9512710329369!2d127.79392693943234!3d26.33911981517252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sjp!4v1688905624397!5m2!1sen!2sjp'
                title='location'
                allowFullScreen=''
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                className='mt-4'
              ></iframe>
            </div>
          </div>
          <div className='off-base'>
            <h1 className='pt-4'>OFF BASE?</h1>
            <h5>YOU HAVE OPTIONS</h5>
            <br />
            <ul className='info-steps'>
              <li className='flex'>
                <span className='option'>1</span>
                <span>FREE DOMESTIC SHIPPING</span>
              </li>
              <li className='flex'>
                <span className='option'>2</span>
                <div className='lineSpace-0'>
                  <span>INTERNATIONAL SHIPPING</span>
                  <br />
                  <small>(Shipping expenses paid by customer)</small>
                </div>
              </li>
              <li className='flex'>
                <span className='option'>3</span>
                <span>DIGITAL DOWNLOAD</span>
              </li>
              <li className='flex'>
                <span className='option'>4</span>
                <span>CONTACT US TO ARRANGE A MEET-UP LOCATION</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default ContactScreen
