import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Title from '../../components/Title'

const BusinessScreen = () => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <Header />
      <Title />

      <section className='service-items'>
        {w < 960 ? (
          <div id='title' className='mt-3'>
            <span className='top-line screens'>International Business</span>
          </div>
        ) : (
          <div className='service-dekstop'>
            <div className='service-desktop-title'>
              <div className='service-desktop-title-left'>
                <span className='upper-text'>
                  International <br />
                  Business
                </span>
              </div>
              <div className='service-desktop-title-right'>
                <Link className='btn' to='/submitrequest'>
                  REQUEST FORM
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className='container px-4'>
          <h1 className='pb-4'>Business Services for Corporate Customers</h1>
          <h2>Custom Clearance Support</h2>
          <p>
            If you're looking for assistance with the custom clearance process
            when doing business in foreign markets, our custom clearance support
            service as part of our International Business Service is here to
            help. We understand that even small mishaps during the clearance
            process can result in financial losses and delays in distribution
            schedules. That's why we work closely with our partner custom dealer
            to ensure that all necessary documentation is submitted on time and
            that the clearance process goes smoothly. Our team's language skills
            and experience in this area make it easier for our customers to
            navigate the complexities of international trade, while minimizing
            the risk of costly mistakes and delays. We assist the custom
            clearance process in both Japanese and English, so language barriers
            are never a problem. By hiring our custom clearance support service,
            you can clear customs on a timely manner effortlessly and
            efficiently. This not only saves you time and money, but it also
            ensures that your perishable items inside the container arrive at
            their destination in the best possible condition. Choose us for your
            custom clearance needs and experience the peace of mind that comes
            with having a reliable partner in the complex world of international
            trade.
          </p>
          <h2 className='pt-5'>Real Estate Introduction</h2>
          <p>
            Timing and luck would often impact your chance of finding the
            perfect match. Finding the right property can be a challenging task.
            Utilizing our local networks in the real estate industry, we help
            our customers find the right property by direct introduction to
            agencies, broekers, and architects.
          </p>
          <h2 className='pt-5'>Phone and Email Desk</h2>
          <p>
            Are you struggling to communicate with your clients or partners in
            foreign markets due to language barriers or cultural differences?
            Our phone and email desk service, part of our International Business
            Service, is here to help.
          </p>
          <p>
            Our bilingual administrative team provides a personalized and
            attentive approach to our clients. As a small and dedicated team, we
            understand the importance of building strong relationships with our
            clients to ensure their success in foreign markets. Our team works
            closely with you to provide comprehensive and customized service
            tailored to your specific needs, including setting up meetings,
            requesting inquiries, negotiating deals, and answering inquiries.
          </p>
          <p>
            Our monthly billing system is flexible and calculated based on a
            range of variables that go into conducting the tasks. We strive to
            ensure that our fee is fair and reflective of the value we provide
            to your business. We understand that every business has unique
            communication needs, which is why we offer customized services
            beyond simply answering calls and emails.
          </p>
          <p>
            By choosing our phone and email desk service, you can have peace of
            mind knowing that you have a reliable and committed partner to help
            you navigate the complexities of international business. Contact us
            today for a quote and let us show you what our small but dedicated
            team can do for you.
          </p>
          <h2 className='pt-5'>Social Media Assistant</h2>
          <p>
            Are you struggling to communicate your brand’s message and image on
            social media in a polished and sophisticated manner? Our social
            media assistant service, part of our International Business Service,
            can help you elevate your message so it connects with your target
            audience in their preferred language and tone.
          </p>
          <p>
            Our existing clients, such as international membership restaurants
            and lounge, cater to a selective and mature audience who value
            unique experiences, elegance, and ambience in their daily lives. We
            understand the importance of protecting your brand’s image and
            message when communicating with this audience. Our bilingual team
            can assist you in crafting captions and messages that accurately
            convey your brand identity and resonate with your audience,
            regardless of their language.
          </p>
          <p>
            We offer a range of services to suit your needs, from providing
            referrals for trusted photographers to crafting simple captions as
            needed. Our team will work closely with you to understand your
            brand’s identity, messaging, and goals. With this information, we
            can assist you in creating a cohesive and polished social media
            presence that accurately reflects your brand.
          </p>
          <p>
            By choosing our social media assistant service, you can focus on the
            core aspects of your business while leaving the communication of
            your brand’s message and identity to our experienced team. Contact
            us today to learn more about how we can assist you in communicating
            your brand identity on social media.
          </p>
          <h2 className='pt-5'>Hands-On Market Research</h2>
          <p>
            Expanding your business into a new market can be a daunting
            prospect, especially when you’re dealing with a new culture and
            unfamiliar business practices. Our hands-on market research service
            provides the information you need to make data-driven decisions
            about pricing, product placement, and more. We can even conduct
            product research with customer segments to provide constructive
            feedback. In addition to research, we also provide information on
            manufacturers and service providers, attend international trade
            shows on your behalf, and create business contacts to help you hit
            the ground running. Instead of cold calling businesses, we collect
            information in-person locally, allowing us to effectively engage
            with the people and companies you’re looking for. Our in-person
            approach saves you time and hassle with visa processes, so you can
            focus on growing your business with confidence.
          </p>
          <h2 className='pt-5'>Literary Translations</h2>
          <p>
            We understand the importance of maintaining the essence of the
            author’s intended message, even when it is translated into a
            different language. Our experienced team has a deep comprehension of
            the intricacies of fiction novels, non-fiction novels, children’s
            books, and nursery books. We take pride in our ability to not only
            translate but also convey the author’s unique writing style and
            emotions behind the text.
          </p>
          <p>
            Our specialized method of translation ensures that even
            tricky-to-translate jokes and local accents are localized and
            rephrased in a natural way in the target language. With our service,
            you can trust that your work will be accurately and skillfully
            translated without losing its intended effect.
          </p>
          <p>
            So whether you're an author, publisher or simply a lover of
            literature looking to share your favorite books with those who speak
            Japanese or Chinese, we are here to help. With our passion for
            language and dedication to quality, we guarantee that your work will
            resonate with readers in the target language. Contact us today to
            see how we can help you expand your reach and connect with a broader
            audience!
          </p>
        </div>
        <div className='btn-container'>
          <Link className='btn' to='/submitrequest'>
            REQUEST FORM
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default BusinessScreen
