import React from 'react'
import { Link, useParams } from 'react-router-dom'
import HeaderDark from '../components/HeaderDark'
import Loader from '../components/Loader'
import { useGetRequestDetailsQuery } from '../slices/requestsApiSlice'
import { useGetServicesQuery } from '../slices/servicesApiSlice'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import moment from 'moment'

const RequestScreen = () => {
  const { id: requestId } = useParams()
  const { data: request, isLoading } = useGetRequestDetailsQuery(requestId)
  const { data: services } = useGetServicesQuery()

  return (
    <>
      <HeaderDark />
      <section className='profile'>
        <Link to='/profile/myrequests'>
          <h3>
            <MdOutlineArrowBackIosNew className='back-btn' />
          </h3>
        </Link>
        <h1 className='profile-title'>MY REQUEST</h1>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='p-4 request'>
            <div className='request-id'>
              <h5>REQUEST</h5>
              <p>{requestId}</p>
            </div>
            <div className='request-img'>
              {services
                .filter((service) => service.name === request.requestItem)
                .map((filteredService) => (
                  <img
                    key={filteredService._id}
                    src={filteredService.icon}
                    alt='icon'
                  />
                ))}
            </div>
            <div className='request-info'>
              <h5 className='text-uppercase text-center'>
                {request.requestItem}
              </h5>
              <div className='date'>
                <p>Date</p>
                <p>{moment(request.createdAt).format('MMMM DD,  YYYY')}</p>
              </div>
              <div className='total'>
                <p>Total</p>
                {request.totalPrice === 0 ? (
                  <p>Pending</p>
                ) : (
                  <p>${request.totalPrice}</p>
                )}
              </div>
            </div>
            {/* <div className='request-btn'>
              <button disabled={request.isTranslated === false}>
                DOWNLOAD
              </button>
            </div> */}
          </div>
        )}
      </section>
    </>
  )
}

export default RequestScreen
