import { useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useGetUsersQuery } from '../slices/usersApiSlice'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

const BarChart = () => {
  let w = document.documentElement.clientWidth || window.innerWidth
  const { data: users } = useGetUsersQuery()
  const userArr = users || []

  const army = userArr.filter((user) => user.branch === 'ARMY').length
  const navy = userArr.filter((user) => user.branch === 'NAVY').length
  const marines = userArr.filter((user) => user.branch === 'MARINES').length
  const airforce = userArr.filter((user) => user.branch === 'AIR FORCE').length
  const spaceforce = userArr.filter(
    (user) => user.branch === 'SPACE FORCE'
  ).length
  const civilian = userArr.filter((user) => user.branch === 'CIVILIAN').length

  const [serviceData] = useState({
    labels: ['Army', 'Navy', 'Marines', 'Air Force', 'Space Force', 'Civilian'],
    datasets: [
      {
        label: '',
        data: [army, navy, marines, airforce, spaceforce, civilian],
        backgroundColor: '#3585b7',
        barThickness: 18
      }
    ]
  })

  return (
    <Bar
      data={serviceData}
      options={{
        plugins: { legend: { display: false } },
        scales: {
          x: {
            grid: {
              color: 'transparent'
            },
            ticks: {
              color: '#999'
            },
            border: {
              color: '#000'
            }
          },
          y: {
            grid: {
              color: '#bdbdbd',
              tickColor: 'transparent'
            },
            ticks: {
              color: '#999'
            },
            border: {
              color: 'transparent'
            }
          }
        }
      }}
    />
  )
}

export default BarChart
