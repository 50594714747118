import { SERVICES_URL, IMAGES_URL, ICONS_URL } from '../constants'
import { apiSlice } from './apiSlice'

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => ({
        url: SERVICES_URL
      }),
      providedTags: ['Service'],
      keepUnusedDataFor: 5,
      providesTags: ['Services']
    }),
    getServiceDetails: builder.query({
      query: (serviceId) => ({
        url: `${SERVICES_URL}/${serviceId}`
      }),
      keepUnusedDataFor: 5
    }),
    createService: builder.mutation({
      query: () => ({
        url: SERVICES_URL,
        method: 'POST'
      }),
      invalidatesTags: ['Service']
    }),
    updateService: builder.mutation({
      query: (data) => ({
        url: `${SERVICES_URL}/${data.serviceId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Services']
    }),
    uploadServiceImage: builder.mutation({
      query: (data) => ({
        url: `${IMAGES_URL}`,
        method: 'POST',
        body: data
      })
    }),
    uploadServiceIcon: builder.mutation({
      query: (data) => ({
        url: `${ICONS_URL}`,
        method: 'POST',
        body: data
      })
    }),
    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: `${SERVICES_URL}/${serviceId}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetServicesQuery,
  useGetServiceDetailsQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useUploadServiceImageMutation,
  useUploadServiceIconMutation,
  useDeleteServiceMutation
} = servicesApiSlice
