import React from 'react'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const OwnerDashboardScreen = () => {
  const { data: requests, isLoading, error } = useGetRequestsQuery()

  const requestArr = requests || []

  const quotes = requestArr.filter((request) => !request.isQuoted)
  const payments = requestArr.filter(
    (request) => request.isQuoted && !request.isPaid
  )

  return (
    <>
      <HeaderDarkAdmin />
      <section className='dashboard'>
        <h1 className='dashboard-title'>DASHBOARD</h1>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <>
            <div className='container'>
              <Row>
                <Col className='text-center box data-box'>
                  <Link to='/admin/quote'>
                    <div className='data-number'>{quotes.length}</div>
                    <div className='data-title'>Quote</div>
                  </Link>
                </Col>
                <Col className='text-center box data-box'>
                  <Link to='/admin/payment'>
                    <div className='data-number'>{payments.length}</div>
                  </Link>
                </Col>
              </Row>
            </div>
          </>
        )}
      </section>
    </>
  )
}

export default OwnerDashboardScreen
