import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Row, Col } from 'react-bootstrap'
import BarChart from '../../components/BarChart'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetRequestsQuery } from '../../slices/requestsApiSlice'
import { useGetUsersQuery } from '../../slices/usersApiSlice'
import moment from 'moment'

const Dashboard = () => {
  const { data: requests, isLoading, error } = useGetRequestsQuery()
  const { data: users, isLoading: loadingUsers } = useGetUsersQuery()
  const { userInfo } = useSelector((state) => state.auth)
  let w = document.documentElement.clientWidth || window.innerWidth

  const requestArr = requests || []
  const userArr = users || []

  const quotes = requestArr.filter((request) => !request.isQuoted)
  const payments = requestArr.filter(
    (request) => request.isQuoted && !request.isPaid
  )
  const translations = requestArr.filter(
    (request) => request.isQuoted && request.isPaid && !request.isTranslated
  )
  const reviews = requestArr.filter(
    (request) =>
      request.isQuoted &&
      request.isPaid &&
      request.isTranslated &&
      !request.isReviewed
  )
  const deliveries = requestArr.filter(
    (request) =>
      request.isQuoted &&
      request.isPaid &&
      request.isTranslated &&
      request.isReviewed &&
      !request.isDelivered
  )
  const completed = requestArr.filter(
    (request) =>
      request.isQuoted &&
      request.isPaid &&
      request.isTranslated &&
      request.isReviewed &&
      request.isDelivered
  )

  const translators = userArr.filter(
    (user) => user.isTranslator || user.isContractor
  )
  const uso = userArr.filter((user) => user.find === 'USO').length
  const legal = userArr.filter((user) => user.find === 'LEGAL OFFICE').length
  const friends = userArr.filter((user) => user.find === 'FRIENDS').length
  const colleagues = userArr.filter((user) => user.find === 'COLLEAGUES').length
  const social = userArr.filter((user) => user.find === 'FACEBOOK').length
  const flyer = userArr.filter((user) => user.find === 'FLYER').length
  const other = userArr.filter((user) => user.find === 'OTHER').length

  return (
    <>
      <HeaderDarkAdmin />
      {w < 960 ? (
        <div className='mobile'>
          <section className='dashboard'>
            <h1 className='dashboard-title'>DASHBOARD</h1>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <div className='container'>
                  <Row>
                    <Col className='text-center box'>Pending</Col>
                  </Row>

                  <Row>
                    <Col className='text-center box data-box'>
                      <Link to='/admin/quote'>
                        <div className='data-box-number'>{quotes.length}</div>
                        <div className='data-box-title'>Quote</div>
                      </Link>
                    </Col>
                    <Col className='text-center box data-box'>
                      <Link to='/admin/payment'>
                        <div className='data-box-number'>{payments.length}</div>
                        <div className='data-box-title'>Payment</div>
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col className='text-center box data-box'>
                      <Link to='/admin/translate'>
                        <div className='data-box-number'>
                          {translations.length}
                        </div>
                        <div className='data-box-title'>Translation</div>
                      </Link>
                    </Col>
                    <Col className='text-center box data-box'>
                      <Link to='/admin/review'>
                        <div className='data-box-number'>{reviews.length}</div>
                        <div className='data-box-title'>Review</div>
                      </Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col className='text-center box data-box'>
                      <Link to='/admin/delivery'>
                        <div className='data-box-number'>
                          {deliveries.length}
                        </div>
                        <div className='data-box-title'>Delivery/Pickup</div>
                      </Link>
                    </Col>
                    <Col className='text-center box data-box'>
                      {userInfo && userInfo.isOwner ? (
                        <Link to='/admin/translatorlist'>
                          <div className='data-box-number'>
                            {translators.length}
                          </div>
                          <div className='data-box-title'>Translators</div>
                        </Link>
                      ) : (
                        <Link to='/admin/complete'>
                          <div className='data-box-number'>
                            {completed.length}
                          </div>
                          <div className='data-box-title'>Completed</div>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className='charts'>
                  <div className='charts-section text-center'>Stats</div>
                  <div className='chart-container'>
                    <div className='charts-container-title'>
                      Service Component
                    </div>
                    {loadingUsers ? <Loader /> : <BarChart />}
                  </div>
                </div>

                <div className='referral box'>
                  <div className='referral-title'>Referral Source</div>
                  <div className='referral-data'>
                    <div className='referral-data-item'>
                      <div>USO</div>
                      <div>{uso}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Legal Office</div>
                      <div>{legal}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Friends</div>
                      <div>{friends}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Colleagues</div>
                      <div>{colleagues}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Social Media</div>
                      <div>{social}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Flyer</div>
                      <div>{flyer}</div>
                    </div>
                    <div className='referral-data-item'>
                      <div>Other</div>
                      <div>{other}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      ) : (
        <div className='desktop desktop-admin'>
          <section className='dashboard'>
            <div className='dashboard-title'>
              <h1 className='mb-5'>DASHBOARD</h1>
              <h3 className='mb-3'>Pending</h3>
            </div>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <>
                <div className='pending'>
                  <div className='data'>
                    <div className='data-box'>
                      <div className='data-box-number'>{quotes.length}</div>
                      <div className='data-box-title'>Quote</div>
                    </div>
                    <div className='data-info'>
                      {[...quotes].reverse().map((data) => (
                        <Link
                          to={`/admin/quote/${data._id}`}
                          key={data._id}
                          className='data-info-box'
                        >
                          <div className='data-info-box-left'>
                            <div className='name'>
                              <h3>
                                {data.firstName}, {data.lastName}
                              </h3>
                            </div>
                            <div className='service'>
                              <span>{data.requestItem}</span>
                            </div>
                          </div>
                          <div className='data-info-box-right'>
                            <div className='date'>
                              <h3>
                                {moment(data.createdAt).format('DD MMM, YYYY')}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data-box'>
                      <div className='data-box-number'>{payments.length}</div>
                      <div className='data-box-title'>Payment</div>
                    </div>
                    <div className='data-info'>
                      {[...payments].reverse().map((data) => (
                        <Link
                          to={`/admin/payment/${data._id}`}
                          key={data._id}
                          className='data-info-box'
                        >
                          <div className='data-info-box-left'>
                            <div className='name'>
                              <h3>
                                {data.firstName}, {data.lastName}
                              </h3>
                            </div>
                            <div className='service'>
                              <span>{data.requestItem}</span>
                            </div>
                          </div>
                          <div className='data-info-box-right'>
                            <div className='date'>
                              <h3>
                                {moment(data.createdAt).format('DD MMM, YYYY')}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data-box'>
                      <div className='data-box-number'>
                        {translations.length}
                      </div>
                      <div className='data-box-title'>Translation</div>
                    </div>
                    <div className='data-info'>
                      {[...translations].reverse().map((data) => (
                        <Link
                          to={`/admin/translate/${data._id}`}
                          key={data._id}
                          className='data-info-box'
                        >
                          <div className='data-info-box-left'>
                            <div className='name'>
                              <h3>
                                {data.firstName}, {data.lastName}
                              </h3>
                            </div>
                            <div className='service'>
                              <span>{data.requestItem}</span>
                            </div>
                          </div>
                          <div className='data-info-box-right'>
                            <div className='date'>
                              <h3>
                                {moment(data.createdAt).format('DD MMM, YYYY')}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data-box'>
                      <div className='data-box-number'>{reviews.length}</div>
                      <div className='data-box-title'>Review</div>
                    </div>
                    <div className='data-info'>
                      {[...reviews].reverse().map((data) => (
                        <Link
                          to={`/admin/review/${data._id}`}
                          key={data._id}
                          className='data-info-box'
                        >
                          <div className='data-info-box-left'>
                            <div className='name'>
                              <h3>
                                {data.firstName}, {data.lastName}
                              </h3>
                            </div>
                            <div className='service'>
                              <span>{data.requestItem}</span>
                            </div>
                          </div>
                          <div className='data-info-box-right'>
                            <div className='date'>
                              <h3>
                                {moment(data.createdAt).format('DD MMM, YYYY')}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data-box'>
                      <div className='data-box-number'>{deliveries.length}</div>
                      <div className='data-box-title'>Delivery/Pickup</div>
                    </div>
                    <div className='data-info'>
                      {[...deliveries].reverse().map((data) => (
                        <Link
                          to={`/admin/delivery/${data._id}`}
                          key={data._id}
                          className='data-info-box'
                        >
                          <div className='data-info-box-left'>
                            <div className='name'>
                              <h3>
                                {data.firstName}, {data.lastName}
                              </h3>
                            </div>
                            <div className='service'>
                              <span>{data.requestItem}</span>
                            </div>
                          </div>
                          <div className='data-info-box-right'>
                            <div className='date'>
                              <h3>
                                {moment(data.createdAt).format('DD MMM, YYYY')}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

                <div className='charts mt-5'>
                  <h3 className='charts-title text-center mb-3'>Stats</h3>
                  <div className='charts-container'>
                    <div className='charts-container-chart'>
                      <div className='charts-container-chart-title'>
                        Service Component
                      </div>
                      {loadingUsers ? <Loader /> : <BarChart />}
                    </div>
                    <div className='charts-container-chart'>
                      <div className='charts-container-chart-title'>
                        Industry
                      </div>
                      {/* {loadingUsers ? <Loader /> : <BarChart />} */}
                    </div>
                  </div>
                </div>

                <div className='referral mt-5'>
                  <div className='referral-title'>Referral Source</div>
                  <div className='referral-data'>
                    <div className='referral-data-item'>
                      <div className='number'>{uso}</div>
                      <div className='item-title'>USO</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{legal}</div>
                      <div className='item-title'>Legal Office</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{friends}</div>
                      <div className='item-title'>Friends</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{colleagues}</div>
                      <div className='item-title'>Colleagues</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{social}</div>
                      <div className='item-title'>Social Media</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{flyer}</div>
                      <div className='item-title'>Flyer</div>
                    </div>
                    <div className='referral-data-item'>
                      <div className='number'>{other}</div>
                      <div className='item-title'>Other</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </>
  )
}

export default Dashboard
