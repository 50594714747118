import { Link } from 'react-router-dom'

const Service = ({ service }) => {
  return (
    <div className='service-items'>
      <div className='container px-4'>
        <div className='service my-3'>
          <Link
            to={
              service.name === 'International Business'
                ? '/services/business'
                : `/services/${service.name}`
            }
          >
            <div className='overlay'>
              <h2>{service.name}</h2>
            </div>
            <img src={service.image} alt='services' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Service
