import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import HeaderDarkAdmin from '../../components/HeaderDarkAdmin'
import { Form } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import FormContainer from '../../components/FormContainer'
import { toast } from 'react-toastify'
import {
  useUpdateServiceMutation,
  useGetServiceDetailsQuery,
  useUploadServiceImageMutation,
  useUploadServiceIconMutation,
  useDeleteServiceMutation
} from '../../slices/servicesApiSlice'

const ServiceEditScreen = () => {
  const { id: serviceId } = useParams()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState(0)
  const [icon, setIcon] = useState('')
  const [image, setImage] = useState('')

  const {
    data: service,
    isLoading,
    error
  } = useGetServiceDetailsQuery(serviceId)

  const [updateService] = useUpdateServiceMutation()

  const [uploadServiceImage] = useUploadServiceImageMutation()
  const [uploadServiceIcon] = useUploadServiceIconMutation()

  const [deleteService] = useDeleteServiceMutation()

  const navigate = useNavigate()

  useEffect(() => {
    if (service) {
      setName(service.name)
      setDescription(service.description)
      setPrice(service.price)
      setIcon(service.icon)
      setImage(service.image)
    }
  }, [service])

  const submitHandler = async (e) => {
    e.preventDefault()
    const updatedService = {
      serviceId,
      name,
      description,
      price,
      icon,
      image
    }

    const result = await updateService(updatedService)
    if (result.error) {
      toast.error(result.error)
    } else {
      toast.success('Service updated')
      navigate('/admin/servicelist')
    }
  }

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure?')) {
      try {
        await deleteService(id)
        toast.success('Service deleted')
        navigate('/admin/servicelist')
      } catch (err) {
        toast.error(err?.data?.message || err.error)
      }
    }
  }

  const uploadIconHandler = async (e) => {
    const formData = new FormData()
    formData.append('icon', e.target.files[0])
    try {
      const res = await uploadServiceIcon(formData).unwrap()
      toast.success(res.message)
      setIcon(res.icon)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }
  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('image', e.target.files[0])
    try {
      const res = await uploadServiceImage(formData).unwrap()
      toast.success(res.message)
      setImage(res.image)
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <HeaderDarkAdmin />
      <section className='edit'>
        <FormContainer>
          <div className='p-4'>
            <Link to='/admin/servicelist'>
              <h3>
                <MdOutlineArrowBackIosNew className='back-btn' />
              </h3>
            </Link>
            <h1 className='edit-title'>EDIT SERVICE</h1>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId='name' className='my-3'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='NAME'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='description' className='my-3'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={4}
                    placeholder='DESCRIPTION'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='price' className='my-3'>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='PRICE'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='icon' className='my-2'>
                  <Form.Label>Icon</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Icon URL'
                    value={icon}
                    onChange={(e) => setIcon(e.target.value)}
                  ></Form.Control>
                  <Form.Control
                    type='file'
                    label='Choose File'
                    onChange={uploadIconHandler}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='image' className='my-2'>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Image URL'
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  ></Form.Control>
                  <Form.Control
                    type='file'
                    label='Choose File'
                    onChange={uploadFileHandler}
                  ></Form.Control>
                </Form.Group>
                <button type='submit' className='button' disabled={isLoading}>
                  UPDATE
                </button>
                <button
                  className='button delete'
                  onClick={() => deleteHandler(service._id)}
                >
                  DELETE
                </button>
              </Form>
            )}
          </div>
        </FormContainer>
      </section>
    </>
  )
}

export default ServiceEditScreen
