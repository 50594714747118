import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import Service from '../components/Service'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { useGetServicesQuery } from '../slices/servicesApiSlice'
import event from '../assets/event.png'
import legal from '../assets/legal.jpg'
import medical from '../assets/medical.jpg'
import technical from '../assets/technical.jpg'
import international from '../assets/international.png'
import interpretation from '../assets/interpretation.png'

const ServicesScreen = () => {
  const { data: services, isLoading, error } = useGetServicesQuery()
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Header />
          <Title image={event} alt='event' />
          {w < 960 ? (
            <section className='service-items'>
              <div id='title' className='mt-3'>
                <span className='top-line screens'>SERVICES</span>
              </div>
              <div className='container'>
                <h2 className='pb-1'>LOST IN TRANSLATION?</h2>
                <p className='px-4 pb-2'>
                  We provide various services to fit your needs.
                </p>
              </div>
              <Row>
                {services.map((service) => (
                  <Col key={service._id} sm={12} md={6} lg={4} xl={3}>
                    <Service service={service} />
                  </Col>
                ))}
              </Row>
            </section>
          ) : (
            <section className='service-items'>
              <div className='service-items-title'>
                <div className='service-items-title-left'>
                  <span className='upper-text'>Services</span>
                </div>
                <div className='service-items-title-right'>
                  <Link className='btn' to='/submitrequest'>
                    REQUEST FORM
                  </Link>
                </div>
              </div>

              <div className='container px-4'>
                <div className='service-items-welcome'>
                  <h1>LOST IN TRANSLATION?</h1>
                  <h2>WE PROVIDE VARIOUS SERVICES TO MEET YOUR NEEDS.</h2>
                </div>
                <div className='service-items-block'>
                  <img src={legal} alt='legal' />
                  <div>
                    <h2>LEGAL</h2>
                    <p>
                      We offer comprehensive legal translation services,
                      including translations of housing lease agreements,
                      employment contracts, family law documents, legal
                      contracts, court orders, and more. Our services also
                      extend to court interpretations at various locations, tax
                      and finance translations, and official document
                      translations for marriage, birth certificates, resident
                      records, and other official records and certificates.
                    </p>
                    <Link className='btn' to='/services/legal'>
                      LEARN MORE
                    </Link>
                  </div>
                </div>
                <div className='service-items-block'>
                  <div>
                    <h2>MEDICAL</h2>
                    <p>
                      Our medical translation services offer on-site medical
                      interpretations with a designated translator to accompany
                      you during your off-base doctor visits, providing you with
                      interpretation summary reports for unofficial
                      record-keeping. Additionally, we provide translations of
                      medical documents such as diagnosis reports, referral
                      letters, treatment records, treatment cost statements, as
                      well as pet document translations including vaccination
                      certificates and other related reports and certificates
                      for traveling with pets.
                    </p>
                    <Link className='btn' to='/services/medical'>
                      LEARN MORE
                    </Link>
                  </div>
                  <img src={medical} alt='medical' />
                </div>
                <div className='service-items-block'>
                  <img src={technical} alt='technical' />
                  <div>
                    <h2>TECHNICAL</h2>
                    <p>
                      Our technical translation services specialize in
                      translations of vehicle titles, export certificates,
                      repair quotations, yacht manuals, purchase agreement, and
                      other registration and certificate documents.
                      Additionally, our bilingual administrative services
                      provide comprehensive support for purchasing, payment
                      plans, in-person appointments, and more.
                    </p>
                    <Link className='btn' to='/services/technical'>
                      LEARN MORE
                    </Link>
                  </div>
                </div>
                <div className='service-items-block'>
                  <div>
                    <h2>INTERPRETATION</h2>
                    <p>
                      Our bilingual administrative services provide
                      comprehensive support for various tasks, including making
                      inquiries, booking appointments, sending emails, filling
                      out applications, and more. Additionally, our on-site
                      interpretations ensure that you have a translator
                      available for appointments throughout Okinawa. We also
                      offer the option of a professional bilingual emcee or
                      event announcer for any occasion in Okinawa.
                    </p>
                    <Link className='btn' to='/services/interpretation'>
                      LEARN MORE
                    </Link>
                  </div>
                  <img src={interpretation} alt='interpretation' />
                </div>
                <div className='service-items-block'>
                  <img src={international} alt='international' />
                  <div>
                    <h2>INTERNATIONAL BUSINESS</h2>
                    <p>
                      Our international business support services cater to
                      corporate customers, offering a range of valuable services
                      including custom clearance support, real estate
                      introductions, phone and email desk support, social media
                      management assistance, hands-on market research, and
                      literary translations.
                    </p>
                    <Link className='btn' to='/services/business'>
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          )}
          <Footer />
        </>
      )}
    </>
  )
}

export default ServicesScreen
