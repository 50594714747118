import React from 'react'
import DownloadButton from '../components/DownloadButton'

const Documents = ({ request, downloadError }) => {
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      {w < 960 ? (
        <div className='status-documents'>
          {request.translation ? (
            <>
              <h5>Documents</h5>
              <div className='duo'>
                {request.translation && (
                  <button
                    className='btn'
                    filename={request.translation}
                    // onClick={() => downloadFile(request.translation)}
                  >
                    TRANSLATED
                  </button>
                )}
                {request.document && (
                  <DownloadButton
                    requestId={request._id}
                    filename={request.document}
                    label={downloadError ? 'RETRY' : 'ORIGINAL'}
                  />
                )}
              </div>
            </>
          ) : request.document ? (
            <>
              <h5>Document</h5>
              <DownloadButton
                requestId={request._id}
                filename={request.document}
                label={downloadError ? 'RETRY' : 'ORIGINAL'}
              />
            </>
          ) : (
            <div className='disabled-wrapper'>
              <h5 className='text-center'>No Document</h5>
            </div>
          )}
        </div>
      ) : (
        <div className='status-documents'>
          {request.translation ? (
            <>
              <h5 className='status-documents-title'>Documents</h5>
              <div className='duo'>
                {request.translation && (
                  <button
                    className='pri-btn'
                    filename={request.translation}
                    // onClick={() => downloadFile(request.translation)}
                  >
                    <h5>TRANSLATED</h5>
                  </button>
                )}
                {request.document && (
                  <DownloadButton
                    requestId={request._id}
                    filename={request.document}
                    label={downloadError ? 'RETRY' : 'ORIGINAL'}
                  />
                )}
              </div>
            </>
          ) : request.document ? (
            <>
              <DownloadButton
                requestId={request._id}
                filename={request.document}
                label={downloadError ? 'RETRY' : 'ORIGINAL'}
              />
            </>
          ) : (
            <div className='disabled-wrapper'>
              <h5 className='text-center'>No Document</h5>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Documents
