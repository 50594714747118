import { useState } from 'react'
import Loader from '../components/Loader'
import {
  useGetRequestDetailsQuery,
  useCreateMessageMutation
} from '../slices/requestsApiSlice'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import { IoSend } from 'react-icons/io5'

const InboxMessage = ({ requestId }) => {
  const {
    data: request,
    isLoading,
    refetch
  } = useGetRequestDetailsQuery(requestId)
  const [createMessage, { isLoading: loadingMessage }] =
    useCreateMessageMutation()
  const { userInfo } = useSelector((state) => state.auth)

  const [comment, setComment] = useState('')
  // const [adminViewed, setAdminViewed] = useState(true)

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      await createMessage({
        requestId,
        comment
      }).unwrap()
      toast.success('Message submitted')
      refetch()
      setComment('')
    } catch (err) {
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <div className='status-messages'>
        {isLoading ? (
          <Loader />
        ) : request.inbox.length === 0 ? (
          <p className='status-messages-comment'>No Messages...</p>
        ) : (
          request.inbox.map((message) => (
            <div key={message._id}>
              {message.name === 'CTS' ? (
                <div className='status-messages-comment system-dark'>
                  <h5>{message.name}</h5>
                  <p>{message.comment}</p>
                  <small>
                    {moment(message.createdAt).format('DD MMM YYYY')}
                    {' at '}
                    {moment(message.createdAt).format('H:mm')}
                  </small>
                </div>
              ) : message.name ===
                `${userInfo.firstName} ${userInfo.lastName}` ? (
                <div className='status-messages-comment right'>
                  <h5>{message.name}</h5>
                  <p>{message.comment}</p>
                  <small>
                    {moment(message.createdAt).format('DD MMM YYYY')}
                    {' at '}
                    {moment(message.createdAt).format('H:mm')}
                  </small>
                </div>
              ) : (
                <div className='status-messages-comment'>
                  <h5>{message.name}</h5>
                  <p>{message.comment}</p>
                  <small>
                    {moment(message.createdAt).format('DD MMM YYYY')}
                    {' at '}
                    {moment(message.createdAt).format('H:mm')}
                  </small>
                </div>
              )}
            </div>
          ))
        )}
        <div>
          <Form className='status-messages-form' onSubmit={submitHandler}>
            <Form.Group controlId='comment'>
              <Form.Control
                as='textarea'
                required
                value={comment}
                placeholder='Write a message...'
                onChange={(e) => setComment(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <button disabled={loadingMessage} type='submit'>
              <IoSend />
            </button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default InboxMessage
