import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderDark from '../components/HeaderDark'
import { FiUser, FiBox } from 'react-icons/fi'
import { MdArrowForwardIos } from 'react-icons/md'
import { HiOutlineEnvelope } from 'react-icons/hi2'
import Footer from '../components/Footer'

const ProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth)
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <div>
      <HeaderDark />
      <section className='profile'>
        <h1 className='text-center text-uppercase profile-title'>
          {userInfo.firstName}'s Profile
        </h1>
        <div className='p-4'>
          {w < 960 ? (
            <div className='mobile'>
              <div className='profile-selection mt-4'>
                <ul>
                  <Link to='/profile/info'>
                    <li>
                      <div className='left'>
                        <FiUser /> My Info
                      </div>
                      <div className='right'>
                        <MdArrowForwardIos />
                      </div>
                    </li>
                  </Link>
                  <Link to='/profile/myrequests'>
                    <li>
                      <div className='left'>
                        <FiBox /> My Requests
                      </div>
                      <div className='right'>
                        <MdArrowForwardIos />
                      </div>
                    </li>
                  </Link>
                  <Link to='/profile/inbox'>
                    <li>
                      <div className='left'>
                        <HiOutlineEnvelope /> Inbox
                      </div>
                      <div className='right'>
                        <MdArrowForwardIos />
                      </div>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          ) : (
            <div className='desktop'>
              <div className='profile-selection'>
                <Link className='profile-selection-link' to='/profile/info'>
                  <FiUser stroke-width='0.5' />
                  <span>My Info</span>
                </Link>
                <Link
                  className='profile-selection-link'
                  to='/profile/myrequests'
                >
                  <FiBox stroke-width='0.5' />
                  <span>My Requests</span>
                </Link>
                <Link className='profile-selection-link' to='/profile/inbox'>
                  <HiOutlineEnvelope stroke-width='0.5' />
                  <span>Inbox</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ProfileScreen
