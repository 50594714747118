import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/AdminRoute'
import OwnerRoute from './components/OwnerRoute'
import HomeScreen from './screens/HomeScreen'
import ServicesScreen from './screens/ServicesScreen'
import MilitaryScreen from './screens/MilitaryScreen'
import LegalScreen from './screens/Services/LegalScreen'
import MedicalScreen from './screens/Services/MedicalScreen'
import TechnicalScreen from './screens/Services/TechnicalScreen'
import InterpretationScreen from './screens/Services/InterpretationScreen'
import BusinessScreen from './screens/Services/BusinessScreen'
import AboutScreen from './screens/AboutScreen'
import ContactScreen from './screens/ContactScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ProfileEditScreen from './screens/ProfileEditScreen'
import ProfileInfoScreen from './screens/ProfileInfoScreen'
import ProfileRequestsScreen from './screens/ProfileRequestsScreen'
import InboxScreen from './screens/InboxScreen'
import MessageScreen from './screens/MessageScreen'
import SubmitRequestScreen from './screens/SubmitRequestScreen'
import RequestScreen from './screens/RequestScreen'
import DashboardScreen from './screens/Admin/DashboardScreen'
import AdminInboxScreen from './screens/Admin/AdminInboxScreen'
import QuotesScreen from './screens/Admin/QuotesScreen'
import QuoteScreen from './screens/Admin/QuoteScreen'
import ServiceListScreen from './screens/Admin/ServiceListScreen'
import ServiceEditScreen from './screens/Admin/ServiceEditScreen'
import PaymentsScreen from './screens/Admin/PaymentsScreen'
import TranslatesScreen from './screens/Admin/TranslatesScreen'
import TranslateScreen from './screens/Admin/TranslateScreen'
import ReviewsScreen from './screens/Admin/ReviewsScreen'
import ReviewScreen from './screens/Admin/ReviewScreen'
import DeliveriesScreen from './screens/Admin/DeliveriesScreen'
import DeliveryScreen from './screens/Admin/DeliveryScreen'
import CompletesScreen from './screens/Admin/CompletesScreen'
import CompleteScreen from './screens/Admin/CompleteScreen'
import UserListScreen from './screens/Admin/UserListScreen'
import UserInfoScreen from './screens/Admin/UserInfoScreen'
import UserEditScreen from './screens/Admin/UserEditScreen'
import PaymentScreen from './screens/Admin/PaymentScreen'
import TranslatorListScreen from './screens/Admin/TranslatorListScreen'
import TranslatorScreen from './screens/Admin/TranslatorScreen'
import OwnerDashboardScreen from './screens/Owner/OwnerDashboardScreen'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      {/* Public */}
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route path='/military' element={<MilitaryScreen />} />
      <Route path='/about' element={<AboutScreen />} />
      <Route path='/contact' element={<ContactScreen />} />
      <Route path='/services' element={<ServicesScreen />} />
      <Route path='/services/legal' element={<LegalScreen />} />
      <Route path='/services/medical' element={<MedicalScreen />} />
      <Route path='/services/technical' element={<TechnicalScreen />} />
      <Route
        path='/services/interpretation'
        element={<InterpretationScreen />}
      />
      <Route path='/services/business' element={<BusinessScreen />} />
      <Route path='/login' element={<LoginScreen />} />
      <Route path='/register' element={<RegisterScreen />} />

      {/* Private */}
      <Route path='' element={<PrivateRoute />}>
        <Route path='/submitrequest' element={<SubmitRequestScreen />} />
        <Route path='/request/:id' element={<RequestScreen />} />
        <Route path='/profile' element={<ProfileScreen />} />
        <Route path='/profile/edit' element={<ProfileEditScreen />} />
        <Route path='/profile/info' element={<ProfileInfoScreen />} />
        <Route path='/profile/myrequests' element={<ProfileRequestsScreen />} />
        <Route path='/profile/inbox' element={<InboxScreen />} />
        <Route path='/profile/inbox/:id/messages' element={<MessageScreen />} />
      </Route>

      {/* Admin */}
      <Route path='' element={<AdminRoute />}>
        <Route path='/admin/dashboard' element={<DashboardScreen />} />
        <Route path='/admin/inbox' element={<AdminInboxScreen />} />
        <Route path='/admin/quote' element={<QuotesScreen />} />
        <Route path='/admin/quote/:id' element={<QuoteScreen />} />
        <Route path='/admin/payment' element={<PaymentsScreen />} />
        <Route path='/admin/payment/:id' element={<PaymentScreen />} />
        <Route path='/admin/translate' element={<TranslatesScreen />} />
        <Route path='/admin/translate/:id' element={<TranslateScreen />} />
        <Route path='/admin/review' element={<ReviewsScreen />} />
        <Route path='/admin/review/:id' element={<ReviewScreen />} />
        <Route path='/admin/delivery' element={<DeliveriesScreen />} />
        <Route path='/admin/delivery/:id' element={<DeliveryScreen />} />
        <Route path='/admin/complete' element={<CompletesScreen />} />
        <Route path='/admin/complete/:id' element={<CompleteScreen />} />
        <Route path='/admin/servicelist' element={<ServiceListScreen />} />
        <Route path='/admin/service/:id/edit' element={<ServiceEditScreen />} />
        <Route path='/admin/userlist' element={<UserListScreen />} />
        <Route path='/admin/user/:id' element={<UserInfoScreen />} />
        <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
        <Route
          path='/admin/translatorlist'
          element={<TranslatorListScreen />}
        />
        <Route
          path='/admin/translatorlist/:id'
          element={<TranslatorScreen />}
        />
      </Route>

      {/* Owner */}
      <Route path='' element={<OwnerRoute />}>
        <Route path='/owner/dashboard' element={<OwnerDashboardScreen />} />
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

reportWebVitals()
