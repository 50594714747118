import { REQUESTS_URL, UPLOAD_URL, TRANSLATIONS_URL } from '../constants'
import { apiSlice } from './apiSlice'

export const requestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createRequest: builder.mutation({
      query: (request) => ({
        url: REQUESTS_URL,
        method: 'POST',
        body: { ...request }
      })
    }),
    updateRequest: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}`,
        method: 'PUT',
        body: data
      })
    }),
    getRequestDetails: builder.query({
      query: (requestId) => ({
        url: `${REQUESTS_URL}/${requestId}`
      }),
      keepUnusedDataFor: 5
    }),
    payRequest: builder.mutation({
      query: ({ requestId, details }) => ({
        url: `${REQUESTS_URL}/${requestId}/paid`,
        method: 'PUT',
        body: { ...details }
      }),
      keepUnusedDataFor: 5
    }),
    getRequests: builder.query({
      query: (requests) => ({
        url: REQUESTS_URL
      }),
      keepUnusedDataFor: 5
    }),
    uploadRequestDocument: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: 'POST',
        body: data
      })
    }),
    uploadTranslation: builder.mutation({
      query: (data) => ({
        url: `${TRANSLATIONS_URL}`,
        method: 'POST',
        body: data
      })
    }),
    getMyRequests: builder.query({
      query: () => ({
        url: `${REQUESTS_URL}/myrequests`
      }),
      keepUnusedDataFor: 5
    }),
    createPaidAmount: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}/paid`,
        method: 'PUT',
        body: data
      })
    }),
    addTranslator: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}/translator`,
        method: 'PUT',
        body: data
      })
    }),
    returnRequest: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}/return`,
        method: 'PUT',
        body: data
      })
    }),
    createNote: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}/notes`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    }),
    createMessage: builder.mutation({
      query: (data) => ({
        url: `${REQUESTS_URL}/${data.requestId}/messages`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Request']
    })
  })
})

export const {
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useGetRequestDetailsQuery,
  usePayRequestMutation,
  useGetRequestsQuery,
  useUploadRequestDocumentMutation,
  useUploadTranslationMutation,
  useGetMyRequestsQuery,
  useCreatePaidAmountMutation,
  useAddTranslatorMutation,
  useReturnRequestMutation,
  useCreateNoteMutation,
  useCreateMessageMutation
} = requestsApiSlice
