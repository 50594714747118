import React from 'react'
import { Link } from 'react-router-dom'
import HeaderDark from '../components/HeaderDark'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { useGetMyRequestsQuery } from '../slices/requestsApiSlice'
import Icon from '../components/Icon'
import moment from 'moment'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

const InboxScreen = () => {
  const { data: requests, isLoading, error } = useGetMyRequestsQuery()
  let w = document.documentElement.clientWidth || window.innerWidth

  return (
    <>
      <HeaderDark />
      <section className='profile'>
        <Link to='/profile'>
          <h3>
            <MdOutlineArrowBackIosNew className='back-btn' />
          </h3>
        </Link>
        <h1 className='text-center profile-title'>INBOX</h1>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <div className='p-4 inbox'>
            <div className='mt-4 inbox-data'>
              {[...requests].reverse().map((request) => (
                <div key={request._id} className='bg-color'>
                  <Link to={`/profile/inbox/${request._id}/messages`}>
                    <div className='inbox-data-flex'>
                      <div className='inbox-data-entry'>
                        <Icon requestItem={request.requestItem} />
                        <div className='info'>
                          <h4>{request.requestItem}</h4>
                          {w < 960 && (
                            <small className='small-text'>
                              Requested:{' '}
                              {moment(request.createdAt).format('MMM DD, YYYY')}
                            </small>
                          )}
                        </div>
                      </div>
                      {w >= 960 && (
                        <div className='inbox-data-date'>
                          <h4>
                            {moment(request.createdAt).format('MMM DD, YYYY')}
                          </h4>
                        </div>
                      )}
                    </div>
                    <hr />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default InboxScreen
